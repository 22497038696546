import React from 'react';
import './MusicCard.css';
import MusicIcon from '../../assets/music.svg'
import PlayIcon from '../../assets/play-button.svg'

const MusicCard = ({ backgroundImage, title, musicCount }) => {
  return (
    <div
      className="music-card"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="music-card-content">
        <div className="music-card-text">
          <p className='title'>{title}</p>
          <p>
            <img src={MusicIcon} alt="Music Icon" height={11} width={9} /> {musicCount} music
          </p>
        </div>
        <div className="play-button">
          <img src={PlayIcon} alt="Play Button"/>
        </div>
      </div>
    </div>
  );
};

export default MusicCard;
