import { useState } from "react";
import { CIcon } from "@coreui/icons-react";
import { cilPencil } from "@coreui/icons";
import '../../Pricing.css';

const CustomRadioInput = ({ 
  level, 
  setSelectedOptions, 
  customOptions, 
  setCustomOptions, 
  value, 
  setOptionsArray, // New prop to update the options array
  optionIndex, // The index of the option in the options array
  handleChange,
  selectedOptions
}) => {
  
  const [editMode, setEditMode] = useState({
    level2: false,
    level3: false,
    level4: false,
    level5: false,
    level6: false,
  });

  const handleOptionChange = (e) => {
    const { value } = e.target;
    setCustomOptions(prev => ({ ...prev, [level]: value }));
  };

  const toggleEditMode = () => {
    setEditMode(prev => ({ ...prev, [level]: !prev[level] }));
  };

  const handleOptionSubmit = () => {
    if (customOptions[level].trim()) {
      const newOptionName = customOptions[level];

      // Update the selected option with the new custom name
      setSelectedOptions(prev => ({ ...prev, [level]: newOptionName }));

      // Update the options array with the new custom name based on the current level logic
      setOptionsArray(prev => {
        const updatedArray = [...prev]; // Clone the options array
        const levelMapping = {
          level1: [],
          level2: ["level1"],
          level3: ["level1", "level2"],
          level4: ["level1", "level2", "level3"],
          level5: ["level1", "level2", "level3", "level4"],
          level6: ["level1", "level2", "level3", "level4", "level5"],
        };

        const levels = levelMapping[level] || [];

        let currentOptions = updatedArray;

        // Iterate through the levels to find the correct nested options array
        levels.forEach((lvl, idx) => {
          const selectedOptionName = selectedOptions[lvl];
          const optionAtLevel = currentOptions.find(option => option.name === selectedOptionName);

          if (optionAtLevel && optionAtLevel.options) {
            currentOptions = optionAtLevel.options; // Move to the next nested options
          }
        });

        // Now find the correct option to edit in the currentOptions (which is at the correct level)
        const optionToUpdate = currentOptions[optionIndex]; // Use optionIndex to locate the option

        if (optionToUpdate) {
          optionToUpdate.name = newOptionName; // Update the option name
        }

        return updatedArray; // Return the updated array
      });

      toggleEditMode(); // Exit the edit mode after saving
    }
  };

  return (
    <div className="check_progress custom-input-container">
      <input
        type="radio"
        name={level}
        value={customOptions[level] || value}
        onChange={(e) => handleChange(e, level)}
      />
      {!editMode[level] ? (
        <div className="custom-input-wrapper">
          <label className="custom-input-label">
            {customOptions[level] || value}
          </label>
          <CIcon
            icon={cilPencil}
            className="custom-pencil-icon"
            onClick={toggleEditMode}
          />
        </div>
      ) : (
        <div className="custom-input-wrapper">
          <input
            type="text"
            value={customOptions[level]}
            onChange={handleOptionChange}
            placeholder="Enter custom option"
            className="custom-input-field"
          />
          <button
            type="button"
            className="custom-save-btn"
            onClick={handleOptionSubmit}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomRadioInput;
