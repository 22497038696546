import React from "react";
import "../Auth.css";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FORGOT_PASSWORD_FLOWS } from "../../../assets/constants";
import PasswordInput from "../../common/password-input/PasswordInput";

const ResetPassword = ({ setFlowName }) => {
  const schema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onClickSave = (data) => {
    console.log("passwords", data);
    // API Call to reset password
    //After success
    setFlowName(FORGOT_PASSWORD_FLOWS.PASSWORD_CHANGED);
  };

  return (
    <>
      <h2 className="auth-title">Reset Password</h2>
      <p className="reset_subtitle">Please type something you'll remember</p>
      <form onSubmit={handleSubmit(onClickSave)} className="auth-form">
        <p className="reset_password_label">New password</p>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <PasswordInput
              {...field}
              placeholder="Must be 8 characters"
              name="password"
            />
          )}
        />
        {errors.password && (
          <p className="error-message">{errors.password.message}</p>
        )}
        <p className="reset_password_label">Confirm new password</p>
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <PasswordInput
              {...field}
              placeholder="Should be same as password"
              name="confirmPassword"
            />
          )}
        />
        {errors.confirmPassword && (
          <p className="error-message">{errors.confirmPassword.message}</p>
        )}
        <button type="submit" className="auth-submit">
          Save Password
        </button>
      </form>
    </>
  );
};
export default ResetPassword;
