import React, { useState } from "react";
import { Form, Tab } from "react-bootstrap";
import "../../Pricing.css";
import TablePricingForm from "./CustomPairComponent";
import SingingDanceForm from "./SingingDanceForm";
import ExpiryPricingForm from "./ExpiryPricingForm";
import '../../musicuploard.css'

const FORM_TYPES = {
  TABLE_FORM: "TABLE_FORM",
  SINGING_DANCE: "SINGING_DANCE",
  EXPIRY_PRICING_FORM: "EXPIRY_PRICING_FORM",
};

const Pricing = ({
  prevStep,
  setFormData,
  formType,
  pricingData,
  nextstep,
  formTitle,
}) => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [price, setPrice] = useState();

  const handleFormSave = () => {
    if (formType === "EMAIL_PHONE") {
      setFormData({
        ...pricingData,
        pricingValue: {
          email,
          phone,
        },
      });
      nextstep();
      return;
    }
    if (formType === "CUSTOM") {
      setFormData({
        ...pricingData,
        pricingValue: {
          price,
        },
      });
      nextstep();
    }
  };

  return (
    <>
      {formType === "COMING_SOON" ? (
        <form className="Progress_form">
          <div>
            <h4>{formTitle}</h4>
            <p>Coming Soon</p>
          </div>
          <div className="btn_wapper">
            <button type="button" onClick={prevStep}>
              Back
            </button>
          </div>
        </form>
      ) : formType === "EMAIL_PHONE" ? (
        <form className="Progress_form">
          <div>
            <h4>{formTitle}</h4>
            <Form.Group>
              <Form.Label className="statement_form">Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="statement_form">Phone Number</Form.Label>
              <Form.Control
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter your phone number"
                required
              />
            </Form.Group>
          </div>
          <div className="btn_wapper">
            <button type="button" onClick={prevStep} className="btn-light">
              Back
            </button>
            <button type="button" onClick={handleFormSave}>
              Save & Next
            </button>
          </div>
        </form>
      ) : formType === "CUSTOM" ? (
        <form className="Progress_form">
          <div>
            <h4>{formTitle}</h4>
            <Form.Group>
              <Form.Control
                type="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder="Enter Rs"
                required
              />
            </Form.Group>
          </div>
          <div className="btn_wapper">
            <button type="button" onClick={prevStep} className="btn-light">
              Back
            </button>
            <button type="button" onClick={handleFormSave}>
              Save & Next
            </button>
          </div>
        </form>
      ) : formType === "TABLE_FORM" ? (
        <TablePricingForm
          prevStep={prevStep}
          pricingData={pricingData}
          nextstep={nextstep}
          setFormData={setFormData}
          formTitle = {formTitle}
        />
      ) : formType === "SINGING_DANCE" ? (
        <SingingDanceForm
          prevStep={prevStep}
          pricingData={pricingData}
          nextstep={nextstep}
          setFormData={setFormData}
          formTitle = {formTitle}
        />
      ) : (
        <ExpiryPricingForm
          prevStep={prevStep}
          pricingData={pricingData}
          nextstep={nextstep}
          setFormData={setFormData}
          formType={formType ? formType : "EXPIRY_PRICING_FORM_DEFAULT"}
          formTitle = {formTitle}
        />
      )}
    </>
  );
};

export default Pricing;
