export const getOptionsForLevel = (level, parentArray, selectedOptions) => {
  const selectedParent = parentArray.find(
    (option) => option.name === selectedOptions[level]
  );
  return selectedParent && selectedParent.options?.length > 0
    ? selectedParent.options
    : [];
};

export const getHeaderForCurrentLevel = (
  level,
  parentArray,
  selectedOptions
) => {
  if (level <= 1) return ""; // No previous level exists for level 1.

  let currentOptions = parentArray;

  // Traverse up to the previous level.
  for (let i = 1; i < level; i++) {
    const levelKey = `level${i}`;
    const selectedOption = selectedOptions[levelKey];

    if (!selectedOption) return ""; // Return empty if selection is missing.

    const selectedParent = currentOptions.find(
      (option) => option.name === selectedOption
    );

    if (!selectedParent) return ""; // Return empty if no matching option is found.

    if (i === level - 1) return selectedParent.header || ""; // Return the header of the previous level.

    currentOptions = selectedParent.options || []; // Move to the next level's options.
  }

  return ""; // Default to an empty string if no header is found.
};

export const LEVEL_RESET_MAP = {
  level1: { level2: "", level3: "", level4: "", level5: "", level6: "" },
  level2: { level3: "", level4: "", level5: "", level6: "" },
  level3: { level4: "", level5: "", level6: "" },
  level4: { level5: "", level6: "" },
  level5: { level6: "" },
};

export const calculateDepth = (array, depth = 0) => {
  if (!array || array.length === 0) return depth;
  return Math.max(
    ...array.map((item) => calculateDepth(item.options, depth + 1))
  );
};

export const getLastFormType = (selectedOptions, optionsArray) => {
  let currentOptions = optionsArray;
  let formType = null;

  for (let i = 1; i <= 6; i++) {
    const selectedOption = selectedOptions[`level${i}`];

    if (!selectedOption) {
      // No option selected at this level, return the last found formType
      return formType;
    }

    const foundOption = currentOptions.find(
      (option) => option.name === selectedOption
    );

    if (!foundOption) {
      // Option not found in the current level, return the last formType
      return formType;
    }

    // If the option has a formType, store it
    if (foundOption.formType) {
      formType = foundOption.formType;
    }

    // Move to the next level of options if available
    currentOptions = foundOption.options || [];
  }

  // Return the final formType
  return formType;
};

export const createSelectedOptionsString = (selectedOptions) =>
  Object.values(selectedOptions).filter(Boolean).join(", ");

export const OPTIONS_ARRAY = [
  {
    name: "License only for the song that you selected",
    header: "License only for the song that you selected now to be used at-",
    options: [
      {
        name: "Public places",
        header: "License to use music in public places as",
        options: [
          {
            name: "Background",
            header:
              "The customers are purchasing license for each song, therefore, we recommend you to fix cheaper prices",
            options: [
              {
                name: "Clubs , Pubs, night clubs",
                header: "Public places,Background - Clubs , Pubs, night clubs",
                formType: "TABLE_FORM",
                options: [],
              },
              {
                name: "Restaurants , dining rooms, bars, lounges, coffee houses",
                header:
                  "Public places,Background - Restaurants , dining rooms, bars, lounges, coffee houses etc",
                formType: "TABLE_FORM",
                options: [],
              },
              {
                name: "Banquet halls and auditoriums, sports, service oriented premises, waiting premises, transport services",
                header:
                  "Public places,Background - Banquet halls and auditoriums, sports, service oriented premises, waiting premises, transport services",
                formType: "TABLE_FORM",
                options: [],
              },
              {
                name: "Multiplex and shopping center, arcades, IT , parks etc",
                header:
                  "Public places,Background - Multiplex and shopping center, arcades, IT , parks etc",
                formType: "TABLE_FORM",
                options: [],
              },
              {
                name: "Lodges ,guest houses, vacation homes, resorts etc",
                header:
                  "Public places,Background - Lodges ,guest houses, vacation homes, resorts etc",
                formType: "TABLE_FORM",
                options: [],
              },
              {
                name: "Add an options if needed",
                isEditable: true,
                header: "Public places,Background - #{custom}",
                formType: "TABLE_FORM",
                options: [],
              },
            ],
          },
          {
            name: "Live performance",
            header: "Live performance",
            options: [
              {
                name: "School ,colleges, NGO, Non profit organizations,trusts etc",
                header:
                  "School ,colleges, NGO, Non profit organizations,trusts etc",
                formType: "SINGING_DANCE",
                options: [],
              },
              {
                name: "General Public places and others",
                header: "General Public places and others",
                options: [
                  {
                    name: "Singing",
                    header: "General Public places and others - Singing",
                    options: [
                      {
                        name: "Clubs , Pubs, night clubs",
                        header:
                          "General Public places and others - Singing - Clubs , Pubs, night clubs",
                        formType: "TABLE_FORM",
                        options: [],
                      },
                      {
                        name: "Restaurants , dining rooms, bars, lounges, coffee houses",
                        header:
                          "General Public places and others - Singing - Restaurants , dining rooms, bars, lounges, coffee houses etc",
                        formType: "TABLE_FORM",
                        options: [],
                      },
                      {
                        name: "Banquet halls and auditoriums, sports, service oriented premises, waiting premises, transport services",
                        header:
                          "General Public places and others - Singing - Banquet halls and auditoriums, sports, service oriented premises, waiting premises, transport services",
                        formType: "TABLE_FORM",
                        options: [],
                      },
                      {
                        name: "Multiplex and shopping center, arcades, IT , parks etc",
                        header:
                          "General Public places and others - Singing - Multiplex and shopping center, arcades, IT , parks etc",
                        formType: "TABLE_FORM",
                        options: [],
                      },
                      {
                        name: "Lodges ,guest houses, vacation homes, resorts etc",
                        header:
                          "General Public places and others - Singing - Lodges ,guest houses, vacation homes, resorts etc",
                        formType: "TABLE_FORM",
                        options: [],
                      },
                      {
                        name: "Add an option if needed",
                        isEditable: true,
                        header:
                          "General Public places and others - Singing - #{custom}",
                        formType: "TABLE_FORM",
                        options: [],
                      },
                    ],
                  },
                  {
                    name: "Dance performance",
                    options: [
                      {
                        name: "Clubs , Pubs, night clubs",
                        header:
                          "General Public places and others - Dancing - Clubs , Pubs, night clubs",
                        formType: "TABLE_FORM",
                        options: [],
                      },
                      {
                        name: "Restaurants , dining rooms, bars, lounges, coffee houses",
                        header:
                          "General Public places and others - Dancing - Restaurants , dining rooms, bars, lounges, coffee houses etc",
                        formType: "TABLE_FORM",
                        options: [],
                      },
                      {
                        name: "Banquet halls and auditoriums, sports, service oriented premises, waiting premises, transport services",
                        header:
                          "General Public places and others - Dancing - Banquet halls and auditoriums, sports, service oriented premises, waiting premises, transport services",
                        formType: "TABLE_FORM",
                        options: [],
                      },
                      {
                        name: "Multiplex and shopping center, arcades, IT , parks etc",
                        header:
                          "General Public places and others - Dancing - Multiplex and shopping center, arcades, IT , parks etc",
                        formType: "TABLE_FORM",
                        options: [],
                      },
                      {
                        name: "Lodges ,guest houses, vacation homes, resorts etc",
                        header:
                          "General Public places and others - Dancing - Lodges ,guest houses, vacation homes, resorts etc",
                        formType: "TABLE_FORM",
                        options: [],
                      },
                      {
                        name: "Add an option if needed",
                        isEditable: true,
                        header:
                          "General Public places and others - Dancing - #{custom}",
                        formType: "TABLE_FORM",
                        options: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Commercial /business purpose",
        header: "Commercial /business purpose",
        options: [
          {
            name: "Promotions, Branding, Advertisements",
            header:
              "Promotions, Branding ,Advertisements - Business turnover in the previous year is between Rs",
            options: [
              {
                name: "1 to 100000",
                header: "Promotions, Branding ,Advertisements - 1 to 100000",
                options: [
                  {
                    name: "Web and all social media",
                    header:
                      "Promotions, Branding ,Advertisements - Web and all social media",
                    formType: "EXPIRY_PRICING_FORM_PLATFORM",
                    options: [],
                  },
                  {
                    name: "Broadcast for TV or Radio Platforms",
                    header:
                      "Promotions, Branding ,Advertisements - Broadcast for TV or Radio Platforms",
                    formType: "EXPIRY_PRICING_FORM_REGIONAL",
                    options: [],
                  },
                  {
                    name: "Event or location use",
                    header:
                      "Promotions, Branding ,Advertisements - Event or location use",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                  {
                    name: "Add an option if needed",
                    isEditable: true,
                    header: "Promotions, Branding ,Advertisements - #{custom}",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                ],
              },
              {
                name: "1,00,000 to 5,00,000",
                header:
                  "Promotions, Branding ,Advertisements - 1,00,000 to 5,00,000",
                options: [
                  {
                    name: "Web and all social media",
                    header:
                      "Promotions, Branding ,Advertisements - Web and all social media",
                    formType: "EXPIRY_PRICING_FORM_PLATFORM",
                    options: [],
                  },
                  {
                    name: "Broadcast for TV or Radio Platforms",
                    header:
                      "Promotions, Branding ,Advertisements - Broadcast for TV or Radio Platforms",
                    formType: "EXPIRY_PRICING_FORM_REGIONAL",
                    options: [],
                  },
                  {
                    name: "Event or location use",
                    header:
                      "Promotions, Branding ,Advertisements - Event or location use",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                  {
                    name: "Add an option if needed",
                    isEditable: true,
                    header: "Promotions, Branding ,Advertisements - #{custom}",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                ],
              },
              {
                name: "5,00,000Rs to 50,00,000",
                header:
                  "Promotions, Branding ,Advertisements - 1,00,000 to 5,00,000",
                manualInputForm: "EXPIRY_PRICING_FORM_PLATFORM",
                options: [
                  {
                    name: "Web and all social media",
                    header:
                      "Promotions, Branding ,Advertisements - Web and all social media",
                    formType: "EXPIRY_PRICING_FORM_PLATFORM",
                    options: [],
                  },
                  {
                    name: "Broadcast for TV or Radio Platforms",
                    header:
                      "Promotions, Branding ,Advertisements - Broadcast for TV or Radio Platforms",
                    formType: "EXPIRY_PRICING_FORM_REGIONAL",
                    options: [],
                  },
                  {
                    name: "Event or location use",
                    header:
                      "Promotions, Branding ,Advertisements - Event or location use",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                  {
                    name: "Add an option if needed",
                    isEditable: true,
                    header: "Promotions, Branding ,Advertisements - #{custom}",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                ],
              },
              {
                name: "50,00,000 to 1,00,00,000",
                header:
                  "Promotions, Branding ,Advertisements - 50,00,000 to 1,00,00,000",
                options: [
                  {
                    name: "Web and all social media",
                    header:
                      "Promotions, Branding ,Advertisements - Web and all social media",
                    formType: "EXPIRY_PRICING_FORM_PLATFORM",
                    options: [],
                  },
                  {
                    name: "Broadcast for TV or Radio Platforms",
                    header:
                      "Promotions, Branding ,Advertisements - Broadcast for TV or Radio Platforms",
                    formType: "EXPIRY_PRICING_FORM_REGIONAL",
                    options: [],
                  },
                  {
                    name: "Event or location use",
                    header:
                      "Promotions, Branding ,Advertisements - Event or location use",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                  {
                    name: "Add an option if needed",
                    isEditable: true,
                    header: "Promotions, Branding ,Advertisements - #{custom}",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                ],
              },
              {
                name: "More than 1,00,00,000",
                header:
                  "Promotions, Branding ,Advertisements - More than 1,00,00,000",
                options: [
                  {
                    name: "Web and all social media",
                    header:
                      "Promotions, Branding ,Advertisements - Web and all social media",
                    formType: "EXPIRY_PRICING_FORM_PLATFORM",
                    options: [],
                  },
                  {
                    name: "Broadcast for TV or Radio Platforms",
                    header:
                      "Promotions, Branding ,Advertisements - Broadcast for TV or Radio Platforms",
                    formType: "EXPIRY_PRICING_FORM_REGIONAL",
                    options: [],
                  },
                  {
                    name: "Event or location use",
                    header:
                      "Promotions, Branding ,Advertisements - Event or location use",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                  {
                    name: "Add an option if needed",
                    isEditable: true,
                    header: "Promotions, Branding ,Advertisements - #{custom}",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                ],
              },
            ],
          },
          {
            name: "Film, documentary, shows",
            header:
              "Film,documentary,shows - Business turnover in the previous year is between Rs",
            options: [
              {
                name: "1 to 100000",
                header: "Film, Documentary, Shows - 1 to 100000",
                options: [
                  {
                    name: "Web and all social media",
                    header:
                      "Film, Documentary, Shows - Web and all social media",
                    formType: "EXPIRY_PRICING_FORM_PLATFORM",
                    options: [],
                  },
                  {
                    name: "Broadcast for TV or Radio Platforms",
                    header:
                      "Film, Documentary, Shows - Broadcast for TV or Radio Platforms",
                    formType: "EXPIRY_PRICING_FORM_REGIONAL",
                    options: [],
                  },
                  {
                    name: "Event or location use",
                    header: "Film, Documentary, Shows - Event or location use",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                  {
                    name: "Add an option if needed",
                    isEditable: true,
                    header: "Film, Documentary, Shows - #{custom}",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                ],
              },
              {
                name: "1,00,000 to 5,00,000",
                header: "Film, Documentary, Shows - 1,00,000 to 5,00,000",
                options: [
                  {
                    name: "Web and all social media",
                    header:
                      "Film, Documentary, Shows - Web and all social media",
                    formType: "EXPIRY_PRICING_FORM_PLATFORM",
                    options: [],
                  },
                  {
                    name: "Broadcast for TV or Radio Platforms",
                    header:
                      "Film, Documentary, Shows - Broadcast for TV or Radio Platforms",
                    formType: "EXPIRY_PRICING_FORM_REGIONAL",
                    options: [],
                  },
                  {
                    name: "Event or location use",
                    header: "Film, Documentary, Shows - Event or location use",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                  {
                    name: "Add an option if needed",
                    isEditable: true,
                    header: "Film, Documentary, Shows - #{custom}",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                ],
              },
              {
                name: "5,00,000Rs to 50,00,000",
                header: "Film, Documentary, Shows - 5,00,000 to 50,00,000",
                options: [
                  {
                    name: "Web and all social media",
                    header:
                      "Film, Documentary, Shows - Web and all social media",
                    formType: "EXPIRY_PRICING_FORM_PLATFORM",
                    options: [],
                  },
                  {
                    name: "Broadcast for TV or Radio Platforms",
                    header:
                      "Film, Documentary, Shows - Broadcast for TV or Radio Platforms",
                    formType: "EXPIRY_PRICING_FORM_REGIONAL",
                    options: [],
                  },
                  {
                    name: "Event or location use",
                    header: "Film, Documentary, Shows - Event or location use",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                  {
                    name: "Add an option if needed",
                    isEditable: true,
                    header: "Film, Documentary, Shows - #{custom}",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                ],
              },
              {
                name: "50,00,000 to 1,00,00,000",
                header: "Film, Documentary, Shows - 50,00,000 to 1,00,00,000",
                options: [
                  {
                    name: "Web and all social media",
                    header:
                      "Film, Documentary, Shows - Web and all social media",
                    formType: "EXPIRY_PRICING_FORM_PLATFORM",
                    options: [],
                  },
                  {
                    name: "Broadcast for TV or Radio Platforms",
                    header:
                      "Film, Documentary, Shows - Broadcast for TV or Radio Platforms",
                    formType: "EXPIRY_PRICING_FORM_REGIONAL",
                    options: [],
                  },
                  {
                    name: "Event or location use",
                    header: "Film, Documentary, Shows - Event or location use",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                  {
                    name: "Add an option if needed",
                    isEditable: true,
                    header: "Film, Documentary, Shows - #{custom}",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                ],
              },
              {
                name: "More than 1,00,00,000",
                header: "Film, Documentary, Shows - More than 1,00,00,000",
                options: [
                  {
                    name: "Web and all social media",
                    header:
                      "Film, Documentary, Shows - Web and all social media",
                    formType: "EXPIRY_PRICING_FORM_PLATFORM",
                    options: [],
                  },
                  {
                    name: "Broadcast for TV or Radio Platforms",
                    header:
                      "Film, Documentary, Shows - Broadcast for TV or Radio Platforms",
                    formType: "EXPIRY_PRICING_FORM_REGIONAL",
                    options: [],
                  },
                  {
                    name: "Event or location use",
                    header: "Film, Documentary, Shows - Event or location use",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                  {
                    name: "Add an option if needed",
                    isEditable: true,
                    header: "Film, Documentary, Shows - #{custom}",
                    formType: "EXPIRY_PRICING_FORM_SEATER",
                    options: [],
                  },
                ],
              },
            ],
          },
          {
            name: "Youtube",
            header: "Youtube",
            options: [
              {
                name: "0 to 50,000 subscribers",
                header: "License to use Youtube - 0 to 50,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "50,000 to 5,00,000 subscribers",
                header:
                  "License to use Youtube - 50,000 to 5,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "5,00,000 to 20,00,000 subscribers",
                header:
                  "License to use Youtube - 5,00,000 to 20,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                header:
                  "License to use Youtube - 20 lakhs(2 million) to 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "More than 1 crore (10 million) subscribers",
                header:
                  "License to use Youtube - More than 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
            ],
          },
          {
            name: "Instagram",
            header: "Instagram",
            options: [
              {
                name: "0 to 50,000 subscribers",
                header: "License to use Instagram - 0 to 50,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "50,000 to 5,00,000 subscribers",
                header:
                  "License to use Instagram - 50,000 to 5,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "5,00,000 to 20,00,000 subscribers",
                header:
                  "License to use Instagram - 5,00,000 to 20,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                header:
                  "License to use Instagram - 20 lakhs(2 million) to 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "More than 1 crore (10 million) subscribers",
                header: "License to use Instagram - 0 to 50,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
            ],
          },
          {
            name: "Facebook",
            header: "Facebook",
            options: [
              {
                name: "0 to 50,000 subscribers",
                header: "License to use Facebook - 0 to 50,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "50,000 to 5,00,000 subscribers",
                header:
                  "License to use Facebook - 50,000 to 5,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "5,00,000 to 20,00,000 subscribers",
                header:
                  "License to use Facebook - 5,00,000 to 20,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                header:
                  "License to use Facebook - 20 lakhs(2 million) to 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "More than 1 crore (10 million) subscribers",
                header:
                  "License to use Facebook - More than 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
            ],
          },
          {
            name: "To Create, perform Remix of the song or tune or lyrics",
            header: "To Create , perform Remix of the song or tune or lyrics",
            options: [
              {
                name: "Youtube",
                header:
                  "To Create , perform Remix of the song or tune or lyrics - Youtube",
                options: [
                  {
                    name: "0 to 50,000 subscribers",
                    header: "Youtube - 0 to 50,000 subscribers",
                    formType: "EXPIRY_PRICING_FORM_DEFAULT",
                    options: [],
                  },
                  {
                    name: "50,000 to 5,00,000 subscribers",
                    header: "Youtube - 50,000 to 5,00,000 subscribers",
                    formType: "EXPIRY_PRICING_FORM_DEFAULT",
                    options: [],
                  },
                  {
                    name: "5,00,000 to 20,00,000 subscribers",
                    header: "Youtube - 5,00,000 to 20,00,000 subscribers",
                    formType: "EXPIRY_PRICING_FORM_DEFAULT",
                    options: [],
                  },
                  {
                    name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                    header:
                      "Youtube - 20 lakhs(2 million) to 1 crore (10 million) subscribers",
                    formType: "EXPIRY_PRICING_FORM_DEFAULT",
                    options: [],
                  },
                  {
                    name: "More than 1 crore (10 million) subscribers",
                    header:
                      "Youtube - More than 1 crore (10 million) subscribers",
                    formType: "EXPIRY_PRICING_FORM_DEFAULT",
                    options: [],
                  },
                ],
              },
              {
                name: "Instagram and facebook",
                header:
                  "To Create , perform Remix of the song or tune or lyrics - Facebook, Instagram",
                options: [
                  {
                    name: "0 to 50,000 subscribers",
                    header: "Instagram - 0 to 50,000 subscribers",
                    formType: "EXPIRY_PRICING_FORM_DEFAULT",
                    options: [],
                  },
                  {
                    name: "50,000 to 5,00,000 subscribers",
                    header: "Instagram - 50,000 to 5,00,000 subscribers",
                    formType: "EXPIRY_PRICING_FORM_DEFAULT",
                    options: [],
                  },
                  {
                    name: "5,00,000 to 20,00,000 subscribers",
                    header: "Instagram - 5,00,000 to 20,00,000 subscribers",
                    formType: "EXPIRY_PRICING_FORM_DEFAULT",
                    options: [],
                  },
                  {
                    name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                    header:
                      "Instagram - 20 lakhs(2 million) to 1 crore (10 million) subscribers",
                    formType: "EXPIRY_PRICING_FORM_DEFAULT",
                    options: [],
                  },
                  {
                    name: "More than 1 crore (10 million) subscribers",
                    header:
                      "Instagram - More than 1 crore (10 million) subscribers",
                    formType: "EXPIRY_PRICING_FORM_DEFAULT",
                    options: [],
                  },
                ],
              },
              {
                name: "Broadcast for TV or Radio Platforms",
                header:
                  "To Create , perform Remix of the song or tune or lyrics - Broadcast for TV or Radio Platforms",
                options: [
                  {
                    name: "Regional",
                    header:
                      "To Create , perform Remix of the song or tune or lyrics - Broadcast for TV or Radio Platforms -Regional",
                    formType: "EXPIRY_PRICING_FORM_DEFAULT",
                    options: [],
                  },
                  {
                    name: "National",
                    header:
                      "To Create , perform Remix of the song or tune or lyrics - Broadcast for TV or Radio Platforms -National",
                    formType: "EXPIRY_PRICING_FORM_DEFAULT",
                    options: [],
                  },
                  {
                    name: "International",
                    header:
                      "To Create , perform Remix of the song or tune or lyrics - Broadcast for TV or Radio Platforms -International",
                    formType: "EXPIRY_PRICING_FORM_DEFAULT",
                    options: [],
                  },
                ],
              },
              {
                name: "Perform the remix at Event or public location",
                header: "Perform the remix at Event or public location",
                formType: "EXPIRY_PRICING_FORM_DEFAULT",
                options: [],
              },
              {
                name: "Add an options if needed",
                isEditable: true,
                header:
                  "To Create , perform Remix of the song or tune or lyrics - #{custom}",
                formType: "EXPIRY_PRICING_FORM_DEFAULT",
                options: [],
              },
            ],
          },
          {
            name: "Negotiate with us (copyright owners) directly for other license needs",
            header: "Specific / custom licenses",
            formType: "EMAIL_PHONE",
            options: [],
          },
          {
            name: "Add an option if needed",
            isEditable: true,
            header: "#{custom}",
            options: [
              {
                name: "0 to 50,000 subscribers",
                header: "License to use Youtube - 0 to 50,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "50,000 to 5,00,000 subscribers",
                header:
                  "License to use Youtube - 50,000 to 5,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "5,00,000 to 20,00,000 subscribers",
                header:
                  "License to use Youtube - 5,00,000 to 20,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                header:
                  "License to use Youtube - 20 lakhs(2 million) to 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
              {
                name: "More than 1 crore (10 million) subscribers",
                header:
                  "License to use Youtube - More than 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_SOCIAL",
                options: [],
              },
            ],
          },
        ],
      },
      {
        name: "Metaverse",
        header: "Metaverse",
        formType: "CUSTOM",
        options: [],
      },
      {
        name: "Specific/Custom licenses",
        header: "Specific / custom licenses",
        formType: "EMAIL_PHONE",
        options: [],
      },
    ],
  },
  {
    name: "A common general license to use any song",
    header: "A common general license to use any song",
    formType: "COMING_SOON",
    options: [],
  },
];
