import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap'; // Bootstrap for layout
import './pricingFLow.css'

const CUSTOM_PLATFORM_NAME = {
  EXPIRY_PRICING_FORM_PLATFORM: 'Facebook',
  EXPIRY_PRICING_FORM_REGIONAL: 'Regional',
  EXPIRY_PRICING_FORM_SEATER: '20 seater table',
  EXPIRY_PRICING_FORM_PLATFORM_FILM: 'Facebook',
  EXPIRY_PRICING_FORM_REGIONAL_FILM: 'Regional',
  EXPIRY_PRICING_FORM_SEATER_FILM: '20 seater table',
  EXPIRY_PRICING_FORM_SOCIAL: 'Add heading',
  EXPIRY_PRICING_FORM_CONTENT_SOCIAL: 'Add a heading',
  EXPIRY_PRICING_FORM_DEFAULT: 'Add a heading',
}

const INITIAL_FORM = {
  EXPIRY_PRICING_FORM_PLATFORM: [
    {
      header: 'Youtube',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 7', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 31', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 180 days.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 1 year.', price: '' },
        { heading: 'One time use of the song in your content and using your content with the selected song for life time.', price: '' },
      ],
    }
  ],
  EXPIRY_PRICING_FORM_REGIONAL: [
    {
      header: 'Regional',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 7', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 31', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 180 days.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 1 year.', price: '' },
        { heading: 'One time use of the song in your content and using your content with the selected song for life time.', price: '' },
      ],
    },
    {
      header: 'National',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 7', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 31', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 180 days.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 1 year.', price: '' },
        { heading: 'One time use of the song in your content and using your content with the selected song for life time.', price: '' },
      ],
    },
    {
      header: 'International',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 7', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 31', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 180 days.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 1 year.', price: '' },
        { heading: 'One time use of the song in your content and using your content with the selected song for life time.', price: '' },
      ],
    }
  ],
  EXPIRY_PRICING_FORM_SEATER: [
    {
      header: '30 seater table',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 7', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 31', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 180 days.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 1 year.', price: '' },
        { heading: 'One time use of the song in your content and using your content with the selected song for life time.', price: '' },
      ],
    },
    {
      header: '20 seater table',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 7', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 31', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 180 days.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 1 year.', price: '' },
        { heading: 'One time use of the song in your content and using your content with the selected song for life time.', price: '' },
      ],
    }
  ],
  EXPIRY_PRICING_FORM_PLATFORM_FILM: [
    {
      header: 'Youtube',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for one year.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for life time', price: '' },
      ],
    }
  ],
  EXPIRY_PRICING_FORM_REGIONAL_FILM: [
    {
      header: 'Regional',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for one year.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for life time', price: '' },
      ],
    },
    {
      header: 'National',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for one year.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for life time', price: '' },
      ],
    },
    {
      header: 'International',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for one year.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for life time', price: '' },
      ],
    }
  ],
  EXPIRY_PRICING_FORM_SEATER_FILM: [
    {
      header: '30 Seater Table',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for one year.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for life time', price: '' },
      ],
    }
  ],
  EXPIRY_PRICING_FORM_SOCIAL: [
    {
      header: 'Full song as Background in your video',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for one year.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for life time', price: '' },
      ],
    },
    {
      header: 'To perform the song-Sing / dance / play music',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for one year.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for life time', price: '' },
      ],
    },
    {
      header: 'Add a heading',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for one year.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for life time', price: '' },
      ],
    },
    {
      header: 'Add a heading',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for one year.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for life time', price: '' },
      ],
    }
  ],
  EXPIRY_PRICING_FORM_CONTENT_SOCIAL: [
    {
      header: 'To use full content to edit with your content/video',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for one year.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for life time', price: '' },
      ],
    }
  ],
  EXPIRY_PRICING_FORM_DEFAULT: [
    {
      header: 'Add a heading',
      customPairs: [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for one year.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for life time', price: '' },
      ],
    }
  ]
}

const ExpiryPricingForm = ({ prevStep, nextstep, setFormData, pricingData, formType, formTitle }) => {

  const [forms, setForms] = useState(
    INITIAL_FORM[formType]
  );
  
  const handleFormSave = () => {
    setFormData({
      ...pricingData,
      pricingValues: forms
    })
    nextstep()
  }

  // Function to add a new form section
  const addCustomForm = () => {
    const newForm = {
      header: CUSTOM_PLATFORM_NAME[formType],
      customPairs: formType?.includes('FILM') || formType.includes('CONTENT') || formType.includes('DEFAULT')?
      [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for one year.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for life time', price: '' },
      ] :
      [
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 7', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 31', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 180 days.', price: '' },
        { heading: 'One time use of the song in your content/video and using your content/video with the selected song for 1 year.', price: '' },
        { heading: 'One time use of the song in your content and using your content with the selected song for life time.', price: '' },
      ],
    };
    setForms([...forms, newForm]);
  };

  // Validation function to check if all fields are filled
  const isAllFieldsFilled = () => {
    return forms?.every((form) =>
      form?.customPairs?.every((pair) => pair.price.trim() !== '')
    );
  };

  return (
    <form className="Progress_form">
      <h4 style={{marginLeft: '15px'}}>{formTitle}</h4>
      {forms.map((form, formIndex) => (
        <div
          key={formIndex}
          className="custom-form-section"
          style={{
            // marginBottom: '30px', // Ensure there's enough margin for each section
            paddingBottom: '20px', // Add padding to avoid margin collapsing
            borderBottom: '1px solid #ccc', // Optional visual separation
            margin: "0 15px 30px 15px"
          }}
        >
          {/* Editable Header */}
          <EditableHeader
            header={form.header}
            onHeaderChange={(newHeader) => {
              const updatedForms = forms.map((f, i) =>
                i === formIndex ? { ...f, header: newHeader } : f
              );
              setForms(updatedForms);
            }}
          />

          {/* Custom Pairs (Static Fields) */}
          {form?.customPairs?.map((pair, index) => (
            <Row key={index} style={{ marginBottom: '15px', alignItems: 'center' }}>
              <Col xs={8}>
                <Form.Group controlId={`static-heading-${formIndex}-${index}`}>
                  <Form.Label style={{ fontWeight: 'bold' }}>{pair.heading}</Form.Label>
                  <p style={{ fontStyle: 'italic', color: 'green' }}>
                    {/* Add the expiry description */}
                    {pair.heading.includes('lifetime') ? 'Non Expiry' : 'Expiry'}
                  </p>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group controlId={`price-${formIndex}-${index}`}>
                  <Form.Control
                    type="text"
                    value={pair.price}
                    onChange={(e) => {
                      const updatedPairs = form.customPairs.map((p, i) =>
                        i === index ? { ...p, price: e.target.value } : p
                      );
                      const updatedForms = forms.map((f, i) =>
                        i === formIndex ? { ...f, customPairs: updatedPairs } : f
                      );
                      setForms(updatedForms);
                    }}
                    placeholder="Enter price"
                    style={{
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                      padding: '5px',
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          ))}
        </div>
      ))}

      {/* Add New Form Button */}
      <Button
        variant="link"
        onClick={addCustomForm}
        disabled={!isAllFieldsFilled()} // Disable button if any field is empty
        style={{
          textDecoration: 'none',
          opacity: isAllFieldsFilled() ? 1 : 0.5, // Dim the button if disabled
          width: '95%',
          margin: '0 15px 0 15px'
        }}
      >
        <span>Custom +</span>
      </Button>

      <div className="btn_wapper">
        <button type="button" onClick={prevStep} className="btn-light">
          Back
        </button>
        <button type="button" onClick={handleFormSave} disabled={!isAllFieldsFilled()}>
          Save & Next
        </button>
      </div>
    </form>
  );
};

// Editable Header Component
const EditableHeader = ({ header, onHeaderChange }) => {
  const [isEditing, setIsEditing] = useState(false);

  return isEditing ? (
    <Form.Control
      type="text"
      value={header}
      onChange={(e) => onHeaderChange(e.target.value)}
      onBlur={() => setIsEditing(false)} // Exit edit mode on blur
      autoFocus
      style={{
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: '10px',
        border: 'none',
        outline: 'none',
      }}
    />
  ) : (
    <h2
      style={{ cursor: 'pointer' }}
      onClick={() => setIsEditing(true)} // Enter edit mode on click
    >
      {header}
    </h2>
  );
};

export default ExpiryPricingForm;
