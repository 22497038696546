import React, { useState } from 'react';
import './PriceSelection.css';

const PriceSelection = ({ data, setSelectedOptions }) => {

  // Handle checkbox change
  const handleOptionChange = (groupIndex, optionIndex) => {
    const group = data.selections[groupIndex];
    const option = group.options[optionIndex];
  
    setSelectedOptions((prevState) => {
      const updatedState = [...prevState];
  
      // Check if the option is already selected
      const existingIndex = updatedState.findIndex(
        (item) =>
          item.groupHeader === group.header && item.option.name === option.name
      );
  
      if (existingIndex > -1) {
        // If found, remove it (deselect)
        updatedState.splice(existingIndex, 1);
      } else {
        // If not found, add the new selection
        updatedState.push({ groupHeader: group.header || 'No Header', option });
      }
  
      console.log('Selected Options:', updatedState); // Print to console
      return updatedState;
    });
  };
  

  return (
    <div className="license-selection-container">
      {data.selections.map((group, groupIndex) => (
        <div
        key={groupIndex}
        className={`selection-group ${
          group.header ? 'with-header' : 'without-header'
        }`}
      >
          {group.header && <h5 className="group-header">{group.header}</h5>}

          <div className="options-list">
            {group.options.map((option, optionIndex) => (
              <div key={optionIndex} className="check_progress">
                {data.isSelectOptions && (
                  <input
                    type="checkbox"
                    id={`option-${groupIndex}-${optionIndex}`}
                    onChange={() => handleOptionChange(groupIndex, optionIndex)}
                  />
                )}
                <label
                  htmlFor={`option-${groupIndex}-${optionIndex}`}
                  className="option-label"
                >
                  {`${option.name}${option.price ? `- Rs. ${option.price}` : ''}`}
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PriceSelection;
