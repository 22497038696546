import React, { useMemo } from "react";
import CustomRadioInput from "./CustomRadioInput";
import { getOptionsForLevel } from "../../Pricing.helper";

const RadioGroups = ({
  optionsArray,
  selectedOptions,
  handleChange,
  level,
  setSelectedOptions,
  customOptions,
  setCustomOptions,
  setOptionsArray
}) => {

  const finalOptions = useMemo(() => {
    const levelMapping = {
      level1: [],
      level2: ["level1"],
      level3: ["level1", "level2"],
      level4: ["level1", "level2", "level3"],
      level5: ["level1", "level2", "level3", "level4"],
      level6: ["level1", "level2", "level3", "level4", "level5"],
    };

    const levels = levelMapping[level] || [];
    const options = levels.reduce(
      (acc, currentLevel) =>
        getOptionsForLevel(currentLevel, acc, selectedOptions),
      optionsArray
    );

    return options;
  }, [level, optionsArray, selectedOptions]);

  return (
    <>
      {finalOptions.map((option, index) => {
        if (!option.isEditable) {
          return (
            <div className="check_progress" key={option.name}>
              <input
                type="radio"
                name={level}
                value={option.name}
                checked={selectedOptions[level] === option.name}
                onChange={(e) => handleChange(e, level)}
              />
              <label>{option.name}</label>
            </div>
          );
        } else {
          return (
            <CustomRadioInput
              key={option.name}
              level={level}
              value={option.name}
              setSelectedOptions={setSelectedOptions}
              customOptions={customOptions}
              setCustomOptions={setCustomOptions}
              setOptionsArray={setOptionsArray} // Pass the setter to update optionsArray
              optionIndex={index} // Pass the current option's index
              handleChange = {handleChange}
              selectedOptions={selectedOptions}
            />
          );
        }
      })}
    </>
  );
};

export default RadioGroups;
