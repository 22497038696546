import React, { useState } from 'react';
import './PasswordInput.css';
import EyeIcon from '../../../assets/EyeIcon.svg'

const PasswordInput = ({placeholder, ...props}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="input-container">
        <input
          type={showPassword ? 'text' : 'password'}
          id='password'
          className='input-field'
          placeholder={placeholder}
          {...props}
        />
          <div className='suffix-icon'>
            <img src={EyeIcon} alt="" width={20} height={14} onClick={togglePasswordVisibility}/>
          </div>
      </div>
    </>
  );
};

export default PasswordInput;
