import { isEmpty } from "lodash-es";

// export const LEVEL_RESET_MAP = {
//   level1: { level2: "", level3: "", level4: "", level5: "", level6: "" },
//   level2: { level3: "", level4: "", level5: "", level6: "" },
//   level3: { level4: "", level5: "", level6: "" },
//   level4: { level5: "", level6: "" },
//   level5: { level6: "" },
// };

export const LEVEL_RESET_MAP = {
  level1: { level2: "", level3: "", level4: ""},
  level2: { level3: "", level4: ""},
  level3: { level4: ""}
};

export const calculateBuyerLicenseArrayDepth = (array, depth = 0) => {
  if (!array || array.length === 0) return depth;
  return Math.max(
    ...array.map((item) =>
      calculateBuyerLicenseArrayDepth(item.options, depth + 1)
    )
  );
};

export const getSelectionsOptions = (selectedOptions, optionsArray) => {
  let currentOptions = optionsArray;
  let selections = [];
  let isSelectOptions = false;
  let header = ''

  for (let i = 1; i <= 6; i++) {
    const selectedOption = selectedOptions[`level${i}`];

    if (!selectedOption) {
      // No option selected at this level, return the last found selections
      return { header, isSelectOptions, selections };
    }

    const foundOption = currentOptions.find(
      (option) => option.name === selectedOption
    );

    if (!foundOption) {
      // Option not found in the current level, return the last selections
      return { header, isSelectOptions, selections };
    }

    // If the option has a selections, store it
    if (!isEmpty(foundOption.selections)) {
      selections = foundOption.selections;
      isSelectOptions = foundOption.isSelectOptions;
      header = foundOption.header
    }

    // Move to the next level of options if available
    currentOptions = foundOption.options || [];
  }

  // Return the final selections
  return { header, isSelectOptions, selections };
};

export const OPTIONS_ARRAY = [
  {
    name: "Permission to remix/combine the selected content /video by editing",
    header: 'License only for the content that you selected now',
    options: [
      {
        name: "Commercial / Business purpose",
        header: 'Commercial /business purpose',
        options: [
          {
            name: "Youtube",
            header: 'Youtube with Subscribers',
            options: [
              {
                name: "0 to 50,000 subscribers",
                isNextStepLastStep: true,
                isSelectOptions: true,
                options: [],
                selections: [
                  {
                    header:
                      "To use full content to edit with your content/video",
                    options: [
                      {
                        name: "License validity Expiry ( one year )",
                        price: 1500,
                      },
                      {
                        name: "License validity Non expiry ( For life time )",
                        price: 3000,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Instagram",
            header: 'Instagram with Subscribers',
            options: [
              {
                name: "0 to 50,000 subscribers",
                isNextStepLastStep: true,
                isSelectOptions: true,
                options: [],
                selections: [
                  {
                    header:
                      "To use full content to edit with your content/video",
                    options: [
                      {
                        name: "License validity Expiry ( one year )",
                        price: 3000,
                      },
                      {
                        name: "License validity Non expiry ( For life time )",
                        price: 6000,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Facebook",
            header: 'Facebook with Subscribers',
            isNextStepLastStep: true,
            isSelectOptions: false,
            options: [],
            selections: [
              {
                header: "",
                options: [
                  {
                    name: "Not Available",
                  },
                ],
              },
            ],
          },
          {
            name: "Negotiate with us (copyright owners) directly for other license needs",
            header: 'Negotiate with us (copyright owners) directly for other license needs',
            isNextStepLastStep: true,
            isSelectOptions: false,
            options: [],
            selections: [
              {
                header: "",
                options: [
                  {
                    name: "Not Available",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Specific / Custom licenses",
        header: 'Specific / Custom licenses',
        isNextStepLastStep: true,
        isSelectOptions: false,
        options: [],
        selections: [
          {
            header: "",
            options: [
              {
                name: "Email: copyva.assistance@gmail.com",
              },
              {
                name: "Mobile: 7358971818",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Permission to repost the same content/video without any edits",
    header: 'License only for the content that you selected now',
    options: [
      {
        name: "Commercial /business purpose",
        header: 'Commercial /business purpose',
        options: [
          {
            name: "Youtube",
            header: 'Youtube with Subscribers',
            options: [
              {
                name: '0 to 50,000 subscribers',
                header: 'Youtube with 0 to 50,000 subscribers',
                isNextStepLastStep: true,
                isSelectOptions: true,
                options: [],
                selections: [
                  {
                    header: 'To use full content to edit with your content/video',
                    options: [
                      {
                        name: 'License validity Expiry ( one year )',
                        price: 2000
                      },
                      {
                        name: 'License validity Non expiry ( For life time )',
                        price: 4000
                      }
                    ]
                  }
                ]
              }
            ],
          },
          {
            name: "Instagram",
            header: 'Instagram with Subscribers',
            isNextStepLastStep: true,
            isSelectOptions: false,
            options: [],
            selections: [
              {
                header: "",
                options: [
                  {
                    name: "Not Available",
                  },
                ],
              },
            ],
          },
          {
            name: "Facebook",
            header: 'Facebook with Subscribers',
            isNextStepLastStep: true,
            isSelectOptions: false,
            options: [],
            selections: [
              {
                header: "",
                options: [
                  {
                    name: "Not Available",
                  },
                ],
              },
            ],
          },
          {
            name: "Negotiate with us (copyright owners) directly for other license needs",
            header: 'Negotiate with us (copyright owners) directly for other license needs',
            isNextStepLastStep: true,
            isSelectOptions: false,
            options: [],
            selections: [
              {
                header: "",
                options: [
                  {
                    name: "Not Available",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Specific / Custom licenses",
        header: 'Specific / Custom licenses',
        isNextStepLastStep: true,
        isSelectOptions: false,
        options: [],
        selections: [
          {
            header: "",
            options: [
              {
                name: "Not Available",
              },
            ],
          },
        ],
      },
    ],
  },
];
