import React from "react";
import DoneIcon from "../../../assets/done.svg";
import "../Auth.css";

const PasswordChanged = () => {
  const handleBackToLogin = () => {
    window.location.href = "/auth";
  };

  return (
    <>
      <div className="success_image_div">
        <img
          src={DoneIcon}
          alt=""
          height={300}
          width={300}
          className="success_image"
        />
      </div>
      <p className="password_changed">Password changed</p>
      <p className="changed_successfully">
        Your password has been changed successfully
      </p>
      <button className="verify-button" onClick={handleBackToLogin}>
        Back to Login
      </button>
    </>
  );
};
export default PasswordChanged;
