import React, { useMemo } from "react";
import { getOptionsForLevel } from "../../Pricing.helper";

const BuyRadioGroups = ({
  optionsArray,
  selectedOptions,
  handleChange,
  level,
}) => {
  const finalOptions = useMemo(() => {
    const levelMapping = {
      level1: [],
      level2: ["level1"],
      level3: ["level1", "level2"],
      level4: ["level1", "level2", "level3"],
      level5: ["level1", "level2", "level3", "level4"],
      level6: ["level1", "level2", "level3", "level4", "level5"],
    };

    const levels = levelMapping[level] || [];
    const options = levels.reduce(
      (acc, currentLevel) => getOptionsForLevel(currentLevel, acc, selectedOptions),
      optionsArray
    );

    return options;
  }, [level, optionsArray, selectedOptions]);

  return (
    <>
      {finalOptions.map((option) => (
        <div className="check_progress" key={option.name}>
          <input
            type="radio"
            name={level}
            value={option.name}
            checked={selectedOptions[level] === option.name}
            onChange={(e) => handleChange(e, level)}
          />
          <label>{option.name}</label>
        </div>
      ))}
    </>
  );
};

export default BuyRadioGroups;
