import React from "react";
import "../Auth.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FORGOT_PASSWORD_FLOWS } from "../../../assets/constants";

const ForgetPassword = ({ setFlowName }) => {
  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSendCode = (data) => {
    console.log("data", data);
    //TODO: API call to send otp on mail
    // After API success
    setFlowName(FORGOT_PASSWORD_FLOWS.VERIFY_OTP);
  };

  return (
    <>
      <h2 className="auth-title">Forget Password</h2>
      <p className="forget_sub_title">
        Don't worry! It happens. Please enter the email associated with your
        account.
      </p>
      <div className="email_container">
        <p className="email_title">Email address</p>
        <form onSubmit={handleSubmit(handleSendCode)} className="auth-form">
          <input
            type="text"
            placeholder="Email address"
            {...register("email")}
            className={errors.email ? "input-error" : ""}
          />
          {errors.email && (
            <p className="error-message">{errors.email.message}</p>
          )}
          <button type="submit" className="send-code">
            Send Code
          </button>
        </form>
      </div>
    </>
  );
};
export default ForgetPassword;
