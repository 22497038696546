import React, { useState, useEffect } from "react";
import ProgressBar from "./ProgressBar"; // Assuming you have a ProgressBar component
import "../MusicUplorad/Pricing.css";
import {
  OPTIONS_ARRAY as options,
  LEVEL_RESET_MAP,
} from "./PermissionUpload.helper";
import PricingForm from "../MusicUplorad/components/pricing-flow/PricingForm";
import { calculateDepth, getHeaderForCurrentLevel, getLastFormType, getOptionsForLevel } from "../MusicUplorad/Pricing.helper";
import RadioGroups from "../MusicUplorad/components/pricing-flow/RadioGroups";

const PermissionUpload = ({nextstep,createContent}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [pricingData, setPricingData] = useState({});
  const [OPTIONS_ARRAY, SET_OPTIONS_ARRAY] = useState(options)
  const [formData, setFormData] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({
    level1: "",
    level2: "",
    level3: "",
    level4: "",
  });
  const [customOptions, setCustomOptions] = useState({
    level2: "",
    level3: "",
    level4: "",
  });
  const [maxStep, setMaxStep] = useState(4);

  useEffect(() => {
    localStorage.setItem("pricingStep", currentStep);
  }, [currentStep]);

  const nextStep = (level) => {
    if (selectedOptions[level]) {
      setCurrentStep((prevStep) => Math.min(prevStep + 1, maxStep + 1));
    }
  };

  const prevStep = (level) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [level]: "", // Dynamically update the selected level to an empty string
    }));
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const handleChange = (e, level) => {
    const { value } = e.target;

    const updatedSelectedOption = {
      ...selectedOptions,
      [level]: value,
      ...LEVEL_RESET_MAP[level],
    };

    setSelectedOptions(updatedSelectedOption);

    setPricingData((prevData) => ({
      ...prevData,
      [level]: value,
    }));

    // Calculate max steps based on level1 selection
    if (level === "level1") {
      const selectedLevel1Option = OPTIONS_ARRAY.find(
        (option) => option.name === value
      );
      const newMaxStep = calculateDepth(selectedLevel1Option.options);
      setMaxStep(newMaxStep + 1); // Adding 1 for the final static step
    } else if (level === "level2") {
      const selectedLevel2Option = getOptionsForLevel(
        "level1",
        OPTIONS_ARRAY,
        updatedSelectedOption
      ).find((option) => option.name === value);
      const newMaxStep = calculateDepth(selectedLevel2Option.options);
      setMaxStep(1 + newMaxStep + 1);
    } else if (level === "level3") {
      const selectedLevel3Option = getOptionsForLevel(
        "level2",
        getOptionsForLevel("level1", OPTIONS_ARRAY, updatedSelectedOption),
        updatedSelectedOption
      ).find((option) => option.name === value);
      const newMaxStep = calculateDepth(selectedLevel3Option.options);
      setMaxStep(2 + newMaxStep + 1);
    } else if (level === "level4") {
      const selectedLevel4Option = getOptionsForLevel(
        "level3",
        getOptionsForLevel(
          "level2",
          getOptionsForLevel("level1", OPTIONS_ARRAY, updatedSelectedOption),
          updatedSelectedOption
        ),
        updatedSelectedOption
      ).find((option) => option.name === value);
      const newMaxStep = calculateDepth(selectedLevel4Option.options);
      setMaxStep(3 + newMaxStep + 1);
    } else if (level === "level5") {
      const selectedLevel5Option = getOptionsForLevel(
        "level4",
        getOptionsForLevel(
          "level3",
          getOptionsForLevel(
            "level2",
            getOptionsForLevel("level1", OPTIONS_ARRAY, updatedSelectedOption),
            updatedSelectedOption
          ),
          updatedSelectedOption
        ),
        updatedSelectedOption
      ).find((option) => option.name === value);
      const newMaxStep = calculateDepth(selectedLevel5Option.options);
      setMaxStep(4 + newMaxStep + 1);
    } else if (level === "level6") {
      const selectedLevel6Option = getOptionsForLevel(
        "level5",
        getOptionsForLevel(
          "level4",
          getOptionsForLevel(
            "level3",
            getOptionsForLevel(
              "level2",
              getOptionsForLevel(
                "level1",
                OPTIONS_ARRAY,
                updatedSelectedOption
              ),
              updatedSelectedOption
            ),
            updatedSelectedOption
          ),
          updatedSelectedOption
        ),
        updatedSelectedOption
      ).find((option) => option.name === value);
      const newMaxStep = calculateDepth(selectedLevel6Option.options);
      setMaxStep(5 + newMaxStep + 1);
    }
  };

  const getFinalHeader = (level) => {
    const LEVEL_MAP = {
      2: "level1",
      3: "level2",
      4: "level3",
      5: "level4",
      6: "level5",
    };
    const header =
      getHeaderForCurrentLevel(level, OPTIONS_ARRAY, selectedOptions) || "";
    return header.replace("#{custom}", selectedOptions[LEVEL_MAP[level]]);
  };

  const renderForm = () => {
    switch (currentStep) {
      case maxStep + 1:
        return (
          <PricingForm
            prevStep={prevStep}
            formType={getLastFormType(selectedOptions, OPTIONS_ARRAY)}
            setFormData={setFormData}
            pricingData={pricingData}
            nextstep={nextstep}
            formTitle={getFinalHeader(maxStep + 1)}
          />
        );
      case 1:
        return (
          <form className="Progress_form">
            <div>
              <h4>License for the song that you select:</h4>
              <h6>License to use music in:</h6>
              <RadioGroups
                optionsArray={OPTIONS_ARRAY}
                level="level1"
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                handleChange={handleChange}
                customOptions={customOptions}
                setCustomOptions={setCustomOptions}
                setOptionsArray={SET_OPTIONS_ARRAY}
              />
            </div>
            <div className="btn_wapper">
              <button type="button" onClick={() => nextStep("level1")}>
                Next
              </button>
            </div>
          </form>
        );
      case 2:
        return (
          <form className="Progress_form">
            <div>
              <h4>{getFinalHeader(2)}</h4>
              <RadioGroups
                level="level2"
                optionsArray={OPTIONS_ARRAY}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                handleChange={handleChange}
                customOptions={customOptions}
                setCustomOptions={setCustomOptions}
                setOptionsArray={SET_OPTIONS_ARRAY}
              />
            </div>
            <div className="btn_wapper">
              <button
                type="button"
                onClick={() => prevStep("level2")}
                className="btn-light"
              >
                Back
              </button>
              <button type="button" onClick={() => nextStep("level2")}>
                Next
              </button>
            </div>
          </form>
        );
      case 3:
        return (
          <form className="Progress_form">
            <div>
            <h4>{getFinalHeader(3)}</h4>
              <RadioGroups
                level="level3"
                optionsArray={OPTIONS_ARRAY}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                handleChange={handleChange}
                customOptions={customOptions}
                setCustomOptions={setCustomOptions}
                setOptionsArray={SET_OPTIONS_ARRAY}
              />
            </div>
            <div className="btn_wapper">
              <button
                type="button"
                onClick={() => prevStep("level3")}
                className="btn-light"
              >
                Back
              </button>
              <button type="button" onClick={() => nextStep("level3")}>
                Next
              </button>
            </div>
          </form>
        );
      case 4:
        return (
          <form className="Progress_form">
            <div>
            <h4>{getFinalHeader(4)}</h4>
              <RadioGroups
                level="level4"
                optionsArray={OPTIONS_ARRAY}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                handleChange={handleChange}
                customOptions={customOptions}
                setCustomOptions={setCustomOptions}
                setOptionsArray={SET_OPTIONS_ARRAY}
              />
            </div>
            <div className="btn_wapper">
              <button
                type="button"
                onClick={() => prevStep("level4")}
                className="btn-light"
              >
                Back
              </button>
              <button type="button" onClick={() => nextStep("level4")}>
                Next
              </button>
            </div>
          </form>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h2>Pricing</h2>
        <span>
          Step {currentStep}/{maxStep + 1}
        </span>
      </div>
      <ProgressBar step={currentStep} totalStep={maxStep + 1} />
      {renderForm()}
    </div>
  );
};

export default PermissionUpload
