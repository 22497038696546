import React from 'react';
import MusicCard from '../../../../shared/MusicCard/MusicCard';
import MusicBg from '../../../../assets/music-play-bg.svg'
import './Artist.css'

const Artist = () => {
  // Example data (can come from an API or state)
  const musicData = [
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    },
    {
      backgroundImage: MusicBg,
      title: 'Lorem ipsum dolor',
      subtitle: 'Top album',
      musicCount: 15,
    }
  ]

  return (
    <div className="section">
      <div className="section-header">
        <h2>Artists</h2>
        <a href="/explore-all" className="explore-link">Explore all</a>
      </div>
      <div className="card-grid">
        {musicData.map((item, index) => (
          <MusicCard
            key={index}
            backgroundImage={item.backgroundImage}
            title={item.title}
            subtitle={item.subtitle}
            musicCount={item.musicCount}
          />
        ))}
      </div>
    </div>
  );
};

export default Artist;
