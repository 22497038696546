import React, { useState } from "react";
import { FORGOT_PASSWORD_FLOWS } from "../../assets/constants";
import VerifyOtp from "./sub-components/VerifyOtp";
import ForgetPassword from "./sub-components/ForgetPassword";
import ResetPassword from "./sub-components/ResetPassword";
import PasswordChanged from "./sub-components/PassworChanged";
import "./Auth.css";
import ArrowLeft from "../../assets/arrow-left.svg";

const FLOW_NAME_MAPPER = {
  [FORGOT_PASSWORD_FLOWS.FORGET_PASSWORD]: "Forget Password",
  [FORGOT_PASSWORD_FLOWS.VERIFY_OTP]: "Verify",
  [FORGOT_PASSWORD_FLOWS.RESET_PASSWORD]: "Reset password",
};

const ForgetPasswordContainer = () => {
  const [flowName, setFlowName] = useState(
    FORGOT_PASSWORD_FLOWS.FORGET_PASSWORD
  );

  const handleBackToLogin = () => {
    window.location.href = "/auth";
  };

  const handleBack = () => {
    if (flowName === FORGOT_PASSWORD_FLOWS.FORGET_PASSWORD) {
      handleBackToLogin();
    } else if (flowName === FORGOT_PASSWORD_FLOWS.VERIFY_OTP) {
      setFlowName(FORGOT_PASSWORD_FLOWS.FORGET_PASSWORD);
    } else if (flowName === FORGOT_PASSWORD_FLOWS.RESET_PASSWORD) {
      setFlowName(FORGOT_PASSWORD_FLOWS.VERIFY_OTP);
    }
  };

  const renderFlow = () => {
    switch (flowName) {
      case FORGOT_PASSWORD_FLOWS.FORGET_PASSWORD:
        return <ForgetPassword setFlowName={setFlowName} />;
      case FORGOT_PASSWORD_FLOWS.VERIFY_OTP:
        return <VerifyOtp setFlowName={setFlowName} />;
      case FORGOT_PASSWORD_FLOWS.RESET_PASSWORD:
        return <ResetPassword setFlowName={setFlowName} />;
      case FORGOT_PASSWORD_FLOWS.PASSWORD_CHANGED:
        return <PasswordChanged />;
      default:
        return null;
    }
  };

  const isNotPasswordChangedFlow =
    flowName !== FORGOT_PASSWORD_FLOWS.PASSWORD_CHANGED;

  return (
    <>
      {isNotPasswordChangedFlow && (
        <div className="d-lg-none d-flex mobi_login">
          <div style={{ color: "black", textDecoration: "none", display: 'flex'}}>
            <img src={ArrowLeft} alt="Login_div" height={17} width={16} onClick={handleBack} className="header_icon"/>{" "}
            <p className="header_text">{FLOW_NAME_MAPPER[flowName] || ""}</p>
          </div>
        </div>
      )}
      <div className="auth-right">
        <div className="auth_right_content">
          {renderFlow()}
          {isNotPasswordChangedFlow && (
            <div className="remember_password_container">
            <p className="remember_password">
              Remember password?{" "}
              <span className="log_in" onClick={handleBackToLogin}>
                Log in
              </span>
            </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ForgetPasswordContainer;
