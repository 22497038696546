import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useLocation, Navigate } from "react-router-dom";
import HomeIcon from "../../assets/Home.svg";
import FileIcon from "../../assets/file.svg";
import UploadIcon from "../../assets/upload.svg";
import Artist from "./components/artist/Artist";
import Songs from "./components/songs/Songs";
import MusicContents from "./components/music-contents/MusicContents";
import "./index.css";

const SIDEBAR_MENU = [
  {
    name: "Artist",
    route: "#/1",
    icon: HomeIcon,
  },
  {
    name: "Users",
    route: "#/2",
    icon: FileIcon,
  },
  {
    name: "Piracy Complaints",
    route: "#/3",
    icon: UploadIcon,
  },
  {
    name: "Requested Music",
    route: "#/4",
    icon: HomeIcon,
  },
  {
    name: "Total Songs",
    route: "#/5",
    icon: FileIcon,
  },
  {
    name: "Total Music Contents",
    route: "#/7",
    icon: UploadIcon,
  },
  {
    name: "Featured Artist",
    route: "/admin-dashboard/featured-artists",
    icon: HomeIcon,
  },
  {
    name: "Featured Songs",
    route: "/admin-dashboard/featured-songs",
    icon: FileIcon,
  },
  {
    name: "Featured Music Contents",
    route: "/admin-dashboard/featured-music-contents",
    icon: UploadIcon,
  },
];

const AdminDashboard = ({ isSidebarOpen, setIsSidebarOpen }) => {
  // Accept isSidebarOpen as prop
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);

  const handleItemClick = (path) => {
    if (window.innerWidth <= 768) {
      setIsSidebarOpen(false);
    }
    setActiveItem(path); // Update active item
  };

  useEffect(() => {
    setActiveItem(location.pathname); // Update active item based on route change
  }, [location.pathname]);

  return (
    <div className="admin-layout">
      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
        {SIDEBAR_MENU.map((item, index) => (
          <div className="sidebar-option" key={index}>
            <Link
              to={item.route}
              className={`sidebar-link ${
                activeItem === item.route ? "active" : ""
              }`}
              onClick={() => handleItemClick(item.route)}
            >
              <img src={item.icon} alt="" className="sidebar-icon" />
              <span className="sidebar-text">{item.name}</span>
            </Link>
          </div>
        ))}
      </div>

      {/* Content Area */}
      <div className="content">
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/admin-dashboard/featured-artists" />}
          />
          <Route path="featured-artists" element={<Artist />} />
          <Route path="featured-songs" element={<Songs />} />
          <Route path="featured-music-contents" element={<MusicContents />} />
        </Routes>
      </div>
    </div>
  );
};

export default AdminDashboard;
