import React from 'react'
import First_tab from "../../../../../assets/Frame 29.png";
import Sec_tab from "../../../../../assets/Frame 30.png";
import Third_tab from "../../../../../assets/Frame 31.png";
import Fourth_tab from "../../../../../assets/Frame 33.png";
import Five_img from "../../../../../assets/Frame 34.png";
import Six_img from "../../../../../assets/Frame 35.png";
const Artisttab = () => {
  return (
    <div className='container'>


      <div className="Tab_content">
        <div>
          <img src={Five_img} alt="" />
        </div>
        <div>
          <img src={First_tab} alt="" />
        </div>
        <div>
          <img src={Sec_tab} alt="" />
        </div>
        <div>
          <img src={Third_tab} alt="" />
        </div>
        <div>
          <img src={Fourth_tab} alt="" />
        </div>

        <div>
          <img src={Six_img} alt="" />
        </div>
        <div>
          <img src={Five_img} alt="" />
        </div>
        <div>
          <img src={First_tab} alt="" />
        </div>
        <div>
          <img src={Sec_tab} alt="" />
        </div>
        <div>
          <img src={Third_tab} alt="" />
        </div>
        <div>
          <img src={Fourth_tab} alt="" />
        </div>

        <div>
          <img src={Six_img} alt="" />
        </div>


      </div>
    </div>
  )
}

export default Artisttab