import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap'; // Bootstrap for layout
import './pricingFLow.css'

const TablePricingForm = ({prevStep, pricingData, nextstep, setFormData, formTitle}) => {
  const [forms, setForms] = useState([
    {
      header: '30 seater table',
      customPairs: [
        { heading: 'License Validity one year', price: '' },
        { heading: 'License Validity six month', price: '' },
      ],
    },
  ]);

  const handleFormSave = () => {
    setFormData({
      ...pricingData,
      pricingValues: forms
    })
    nextstep()
  }

  // Function to add a new form with the same structure
  const addCustomForm = () => {
    const newForm = {
      header: '20 Seater Table',
      customPairs: [
        { heading: 'License Validity one year', price: '' },
        { heading: 'License Validity six month', price: '' },
      ],
    };
    setForms([...forms, newForm]);
  };

  // Validation function to check if all required fields are filled
  const isAllFieldsFilled = () => {
    return forms.every((form) =>
      form.customPairs.every((pair) => pair.price.trim() !== '')
    );
  };

  return (
    <form className="Progress_form">
      <h4 style={{marginLeft: '15px'}}>{formTitle}</h4>
      {forms.map((form, formIndex) => (
        <div key={formIndex} style={{
          margin: '0 15px 30px 15px' 
          }}>
          {/* Editable Header */}
          <EditableHeader
            header={form.header}
            onHeaderChange={(newHeader) => {
              const updatedForms = forms.map((f, i) =>
                i === formIndex ? { ...f, header: newHeader } : f
              );
              setForms(updatedForms);
            }}
          />

          {/* Custom Pairs (Static Fields) */}
          {form.customPairs.map((pair, index) => (
            <Row key={index} style={{ marginBottom: '15px', alignItems: 'center' }}>
              <Col xs={8}>
                <Form.Group controlId={`static-heading-${formIndex}-${index}`}>
                  <Form.Label style={{ fontWeight: 'bold' }}>{pair.heading}</Form.Label>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group controlId={`price-${formIndex}-${index}`}>
                  <Form.Control
                    type="text"
                    value={pair.price}
                    onChange={(e) => {
                      const updatedPairs = form.customPairs.map((p, i) =>
                        i === index ? { ...p, price: e.target.value } : p
                      );
                      const updatedForms = forms.map((f, i) =>
                        i === formIndex ? { ...f, customPairs: updatedPairs } : f
                      );
                      setForms(updatedForms);
                    }}
                    placeholder="Enter price"
                    style={{
                      borderRadius: '5px',
                      border: '1px solid black',
                      padding: '5px',
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          ))}
        </div>
      ))}

      {/* Add New Form Button */}
      <Button
        variant="link"
        onClick={addCustomForm}
        disabled={!isAllFieldsFilled()} // Disable button if any field is empty
        style={{
          textDecoration: 'none',
          opacity: isAllFieldsFilled() ? 1 : 0.5,
          width: '95%',
          margin: '0 15px 0 15px'
        }}
      >
        <span>Custom +</span>
      </Button>

      <div className="btn_wapper">
        <button type="button" onClick={prevStep} className="btn-light">
          Back
        </button>
        <button type="button" onClick={handleFormSave} disabled = {!isAllFieldsFilled()}>
          Save & Next
        </button>
      </div>
    </form>
  );
};

// Editable Header Component
const EditableHeader = ({ header, onHeaderChange }) => {
  const [isEditing, setIsEditing] = useState(false);

  return isEditing ? (
    <Form.Control
      type="text"
      value={header}
      onChange={(e) => onHeaderChange(e.target.value)}
      onBlur={() => setIsEditing(false)} // Exit edit mode on blur
      autoFocus
      style={{
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: '10px',
        border: 'none',
        outline: 'none',
      }}
    />
  ) : (
    <h2
      style={{ cursor: 'pointer' }}
      onClick={() => setIsEditing(true)} // Enter edit mode on click
    >
      {header}
    </h2>
  );
};

export default TablePricingForm;
