import React, { useState } from "react";
import './finalForm.css';  // Include your CSS file
import { useNavigate } from "react-router-dom";

const FinalForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    personalAddress: "",
    licenseAddress: "",
    sameAsPersonalAddress: false,
    photos: null,
    agreement1: false,
    agreement2: false,
    agreement3: false,
  });


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => {
      const updatedData = {
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      };

      // Only update licenseAddress if "Same as personal address" is checked
      if (name === "sameAsPersonalAddress") {
        updatedData.licenseAddress = checked ? prevState.personalAddress : prevState.licenseAddress;
      }

      return updatedData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid =
      formData?.name &&
      formData?.phoneNumber &&
      formData?.personalAddress &&
      formData?.licenseAddress &&
      formData?.photos &&
      formData?.agreement1 &&
      formData?.agreement2 &&
      formData?.agreement3;
    if(isValid){
      console.log('formData', formData)
    navigate('/payment')
    }
  };

  return (
    <form onSubmit={handleSubmit} className="license-form">
      <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="phoneNumber">Phone Number:</label>
        <input
          type="text"
          id="phoneNumber"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="personalAddress">Personal Address:</label>
        <textarea
          id="personalAddress"
          name="personalAddress"
          value={formData.personalAddress}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group checkbox">
        <input
          type="checkbox"
          id="sameAsPersonalAddress"
          name="sameAsPersonalAddress"
          checked={formData.sameAsPersonalAddress}
          onChange={handleChange}
        />
        <label htmlFor="sameAsPersonalAddress">Same as personal address</label>
      </div>

      <div className="form-group">
        <label htmlFor="licenseAddress">
          Address of the place of using the license:
        </label>
        <textarea
          id="licenseAddress"
          name="licenseAddress"
          value={formData.licenseAddress}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="photos">
          Upload photos of the place of using the license (or social media
          screenshots):
        </label>
        <input
          type="file"
          id="photos"
          name="photos"
          multiple
          onChange={(e) =>
            setFormData({ ...formData, photos: e.target.files })
          }
        />
      </div>

      <div className="form-group checkbox">
        <input
          type="checkbox"
          id="agreement1"
          name="agreement1"
          checked={formData.agreement1}
          onChange={handleChange}
          required
        />
        <label htmlFor="agreement1">
          I understand that in case of wrongful submission of my details, the
          license purchased will be invalid.
        </label>
      </div>

      <div className="form-group checkbox">
        <input
          type="checkbox"
          id="agreement2"
          name="agreement2"
          checked={formData.agreement2}
          onChange={handleChange}
          required
        />
        <label htmlFor="agreement2">
          I understand that the copyright owner can cancel the agreement for a
          period of 15 days from the date of purchase of the license with
          refund.
        </label>
      </div>

      <div className="form-group checkbox">
        <input
          type="checkbox"
          id="agreement3"
          name="agreement3"
          checked={formData.agreement3}
          onChange={handleChange}
          required
        />
        <label htmlFor="agreement3">
          I understand that Copyva is only a software used by the copyright
          owners and doesn’t engage in issuance/verification/cancellation of the
          license.
        </label>
      </div>

      <button type="submit">
        Submit
      </button>
    </form>
  );
};

export default FinalForm;
