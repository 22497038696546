// OTPInput.jsx
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import './OtpInput.css'; // Import your custom CSS

const OTPInput = ({otp, setOtp}) => {

  const handleChange = (otpValue) => {
    setOtp(otpValue);
  };

  return (
    <div className="otp-container">
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={4}
        separator={<span>&nbsp;&nbsp;</span>}

      renderInput={props => (
        <input
          inputMode="numeric"
          autoComplete="one-time-code"
          {...props}
        />
      )}
        isInputNum={true}
        inputStyle="otp-input" // Applying custom CSS class
        focusStyle="otp-input-focus" // Optional: Adding focus styles
      />
    </div>
  );
};

export default OTPInput;
