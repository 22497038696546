import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { useEffect, useState } from "react";
import "./tabs.css";
import Music_img from "../../../assets/Frame 11 (1).png";
import Content_img from "../../../assets/Frame 11 (2).png";
import Artist_img from "../../../assets/Frame 11 (3).png";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const TabsSection = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [audioSrc, setAudioSrc] = useState("");
  const [videoId, setVideoId] = useState("");
  const [isContent, setIsContent] = useState(false);
  const [content, setContent] = useState([]);
  const [musicItems, setMusicItems] = useState([]);
  const navigate = useNavigate();

  const handler = () => {
    navigate("/viewall");
  };

  const handleImageClick = (audioSrc, isContent) => {
    if (isContent) {
      setShowVideoPlayer(true);
      setIsContent(true);
      setVideoSrc(audioSrc);
      setAudioSrc("");
      setShowAudioPlayer(false);
    } else {
      setIsContent(false);
      setAudioSrc(audioSrc);
      setShowVideoPlayer(false);
      setVideoSrc("");
      setShowAudioPlayer(true);
    }
  };

  const handleCloseAudioPlayer = () => {
    if (isContent) {
      setShowVideoPlayer(false);
      setVideoSrc("");
      setIsContent(false);
    } else {
      setShowAudioPlayer(false);
      setAudioSrc("");
    }
  };

  const getEmbedSrc = (url) => {
    if (url.includes("youtube.com")) {
      const videoId = new URLSearchParams(new URL(url).search).get("v");
      return `https://youtube.com/embed/${videoId}?autoplay=1`;
    }
    return "";
  };

  useEffect(() => {
    const fetchMusic = async () => {
      try {
        const BASE_URL = "http://3.110.212.158:8000";
        const page = 1;
        const pageSize = 10;

        const musicResponse = await axios.get(`${BASE_URL}/api/music_details/`, {
          params: { page, page_size: pageSize },
        });

        const contentResponse = await axios.get(`${BASE_URL}/api/music_details/`, {
          params: { page, page_size: pageSize, upload_type: "Content" },
        });

        setMusicItems(musicResponse.data.results || []);
        setContent(contentResponse.data.results || []);
      } catch (error) {
        console.error("Error fetching music details:", error.message);
      }
    };

    fetchMusic();
  }, []);

  return (
    <section className="Container tabs_section">
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList classID="tab_list">
          <Tab>
            <h4>
              <img src={Music_img} alt="Music_img" />
              Music
            </h4>
          </Tab>
          <Tab>
            <h4>
              <img src={Content_img} alt="Content_img" />
              Content
            </h4>
          </Tab>
          <Tab>
            <h4>
              <img src={Artist_img} alt="Artist_img" />
              Artist
            </h4>
          </Tab>
        </TabList>
        <TabPanel>
          <div className="Tab_content">
            {musicItems.map((item, index) => (
              <div key={index}>
                <img
                  src={item.cover_template_link}
                  alt={item.music_name}
                  onClick={() => handleImageClick(item.upload_music_link, false)}
                />
                {/* <p>{item.music_name}</p>
                <p>{item.artist_name}</p> */}
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="Tab_content">
            {content.map((item, index) => (
              <div key={index}>
                <img
                  src={item.cover_template_link}
                  alt={item.music_name}
                  onClick={() => handleImageClick(item.music_links[0]?.link, true)}
                />
                {/* <p>{item.music_name}</p>
                <p>{item.artist_name}</p> */}
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="Tab_content">
            {musicItems.map((item, index) => (
              <div key={index}>
                <img
                  src={item.cover_template_link}
                  alt={item.music_name}
                  onClick={() => handleImageClick(item.upload_music_link, false)}
                />
                {/* <p>{item.music_name}</p>
                <p>{item.artist_name}</p> */}
              </div>
            ))}
          </div>
        </TabPanel>
      </Tabs>
      <button type="button" className="btn_one" onClick={handler}>
        View all
      </button>
      {showVideoPlayer && (
        <div className="video-player-overlay">
          <div className="video-player">
            <button className="close-button" onClick={handleCloseAudioPlayer}>
              X
            </button>
            <iframe
              style={{ width: "100%", height: "100%", border: "none" }}
              title="Video player"
              src={getEmbedSrc(videoSrc)}
            />
          </div>
        </div>
      )}
      {showAudioPlayer && (
        <div className="video-player-overlay">
          <div className="audio-player-container">
            <button className="close-button" onClick={handleCloseAudioPlayer}>
              X
            </button>
            <audio controls src={audioSrc} className="audio-element"></audio>
          </div>
        </div>
      )}
    </section>
  );
};

export default TabsSection;
