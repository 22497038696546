import { isEmpty } from "lodash-es";

export const LEVEL_RESET_MAP = {
  level1: { level2: "", level3: "", level4: "", level5: "", level6: "" },
  level2: { level3: "", level4: "", level5: "", level6: "" },
  level3: { level4: "", level5: "", level6: "" },
  level4: { level5: "", level6: "" },
  level5: { level6: "" },
};

export const calculateBuyerLicenseArrayDepth = (array, depth = 0) => {
  if (!array || array.length === 0) return depth;
  return Math.max(
    ...array.map((item) =>
      calculateBuyerLicenseArrayDepth(item.options, depth + 1)
    )
  );
};

export const getSelectionsOptions = (selectedOptions, optionsArray) => {
  let currentOptions = optionsArray;
  let selections = [];
  let isSelectOptions = false;
  let header = ''

  for (let i = 1; i <= 6; i++) {
    const selectedOption = selectedOptions[`level${i}`];

    if (!selectedOption) {
      // No option selected at this level, return the last found selections
      return { header, isSelectOptions, selections };
    }

    const foundOption = currentOptions.find(
      (option) => option.name === selectedOption
    );

    if (!foundOption) {
      // Option not found in the current level, return the last selections
      return {header, isSelectOptions, selections };
    }

    // If the option has a selections, store it
    if (!isEmpty(foundOption.selections)) {
      selections = foundOption.selections;
      isSelectOptions = foundOption.isSelectOptions;
      header = foundOption.header
    }

    // Move to the next level of options if available
    currentOptions = foundOption.options || [];
  }

  // Return the final selections
  return { header, isSelectOptions, selections };
};

export const OPTIONS_ARRAY = [
  {
    name: "License only for the song that you selected now to be used at",
    header: 'License only for the song that you selected now to be used at-',
    options: [
      {
        name: "Public places",
        header: 'License to use music in public places as',
        options: [
          {
            name: "Background",
            header: 'Use as Background Music in public places',
            options: [
              {
                name: "Clubs , Pubs, night clubs",
                header: 'Use as Background Music in public places',
                isNextStepLastStep: true,
                isSelectOptions: true,
                options: [],
                selections: [
                  {
                    header: "30 seater table",
                    options: [
                      {
                        name: "License validity one year( 365 days)",
                        price: 500,
                      },
                      {
                        name: "License validity six months( 180 days)",
                        price: 400,
                      },
                    ],
                  },
                  {
                    header: "20 seater table",
                    options: [
                      {
                        name: "License validity one year( 365 days)",
                        price: 250,
                      },
                      {
                        name: "License validity six months( 180 days)",
                        price: 200,
                      },
                    ],
                  },
                  {
                    header: "10 seater table",
                    options: [
                      {
                        name: "License validity one year( 365 days)",
                        price: 100,
                      },
                      {
                        name: "License validity six months( 180 days)",
                        price: 80,
                      },
                    ],
                  },
                ],
              },
              {
                name: "Restaurants , dining rooms, bars, lounges, coffee houses etc",
                header: 'Use as Background Music in public places',
                isNextStepLastStep: true,
                isSelectOptions: true,
                options: [],
                selections: [
                  {
                    header: "30 seater table",
                    options: [
                      {
                        name: "License validity one year( 365 days)",
                        price: 500,
                      },
                      {
                        name: "License validity six months( 180 days)",
                        price: 400,
                      },
                    ],
                  },
                  {
                    header: "20 seater table",
                    options: [
                      {
                        name: "License validity one year( 365 days)",
                        price: 250,
                      },
                      {
                        name: "License validity six months( 180 days)",
                        price: 200,
                      },
                    ],
                  },
                  {
                    header: "10 seater table",
                    options: [
                      {
                        name: "License validity one year( 365 days)",
                        price: 100,
                      },
                      {
                        name: "License validity six months( 180 days)",
                        price: 80,
                      },
                    ],
                  },
                ],
              },
              {
                name: "Multiplex and shopping center, arcades, IT , parks etc",
                header: 'Use as Background Music in public places',
                isNextStepLastStep: true,
                isSelectOptions: true,
                options: [],
                selections: [
                  {
                    header: "50,000 sq.ft",
                    options: [
                      {
                        name: "License validity one year( 365 days)",
                        price: 5000,
                      },
                      {
                        name: "License validity six months( 180 days)",
                        price: 4000,
                      },
                    ],
                  },
                  {
                    header: "20,000 sq.ft",
                    options: [
                      {
                        name: "License validity one year( 365 days)",
                        price: 5000,
                      },
                      {
                        name: "License validity six months( 180 days)",
                        price: 4000,
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Lodges ,guest houses, vacation homes, resorts etc',
                header: 'Use as Background Music in public places',
                isNextStepLastStep: true,
                isSelectOptions: true,
                options: [],
                selections: [
                  {
                    header: '50 room capacity',
                    options: [
                      {
                        name: 'License validity one year ( 365 days )',
                        price: 2000
                      },
                      {
                        name: 'License validity six months ( 180 days )',
                        price: 1500
                      }
                    ]
                  },
                  {
                    header: '10 to 49 room capacity',
                    options: [
                      {
                        name: 'License validity one year ( 365 days )',
                        price: 1000
                      },
                      {
                        name: 'License validity six months ( 180 days )',
                        price: 1500
                      }
                    ]
                  }
                ]
              },
              {
                name: "Banquet halls and auditoriums, sports, service oriented premises, waiting premises, transport services",
                header: 'Use as Background Music in public places',
                isNextStepLastStep: true,
                isSelectOptions: true,
                options: [],
                selections: [
                  {
                    header: "Above 3000 Individual capacity",
                    options: [
                      {
                        name: "License validity one year( 365 days)",
                        price: 3000,
                      },
                      {
                        name: "License validity six months( 180 days)",
                        price: 2500,
                      },
                    ],
                  },
                  {
                    header: "Below 3000 Individual capacity",
                    options: [
                      {
                        name: "License validity one year( 365 days)",
                        price: 2000,
                      },
                      {
                        name: "License validity six months( 180 days)",
                        price: 1800,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Live performance",
            header: 'Use for Live performance in public places',
            options: [
              {
                name: "School ,colleges, NGO, Non profit organizations,trusts etc",
                header: 'Use at School ,colleges, NGO, Non profit organisations,trusts etc',
                isNextStepLastStep: true,
                isSelectOptions: true,
                options: [],
                selections: [
                  {
                    header: "",
                    options: [
                      {
                        name: "Singing",
                        price: 100,
                      },
                      {
                        name: "Dancing",
                        price: 100,
                      },
                    ],
                  },
                ],
              },
              {
                name: "General Public places and others",
                header: 'Use in General Public places and others',
                options: [
                  {
                    name: "Singing",
                    header: 'General Public places and others for Singing',
                    options: [
                      {
                        name: "Clubs , Pubs, night clubs",
                        header: 'Select license coverage',
                        isNextStepLastStep: true,
                        isSelectOptions: true,
                        options: [],
                        selections: [
                          {
                            header: "30 seater table",
                            options: [
                              {
                                name: "License validity one time performance( 1 Day)",
                                price: 500,
                              },
                              {
                                name: "License validity one year use( 365 days)",
                                price: 2500,
                              },
                            ],
                          },
                          {
                            header: "20 seater table",
                            options: [
                              {
                                name: "License validity one time performance( 1 Day)",
                                price: 400,
                              },
                              {
                                name: "License validity one year use( 365 days)",
                                price: 2200,
                              },
                            ],
                          },
                          {
                            header: "10 seater table",
                            options: [
                              {
                                name: "License validity one time performance( 1 Day)",
                                price: 250,
                              },
                              {
                                name: "License validity one year use( 365 days)",
                                price: 1800,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        name: "Restaurants , dining rooms, bars, lounges, coffee houses etc",
                        header: 'Select license coverage',
                        isNextStepLastStep: true,
                        isSelectOptions: true,
                        options: [],
                        selections: [
                          {
                            header: "Above 100 customer capacity",
                            options: [
                              {
                                name: "License validity one time performance( 1 Day)",
                                price: 1000,
                              },
                              {
                                name: "License validity one year use( 365 days)",
                                price: 2500,
                              },
                            ],
                          },
                          {
                            header: "Below 99 customer capacity",
                            options: [
                              {
                                name: "License validity one time performance( 1 Day)",
                                price: 800,
                              },
                              {
                                name: "License validity one year use( 365 days)",
                                price: 2000,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Dancing",
                    header: 'General Public places and others for Dancing',
                    isNextStepLastStep: true,
                    isSelectOptions: false,
                    options: [],
                    selections: [
                      {
                        header: "",
                        options: [
                          {
                            name: "Not Available",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Commercial / Business purpose",
        header: 'Commercial /business purpose',
        options: [
          {
            name: "Promotions, Branding , Advertisements",
            header: 'Business turnover in the previous year is between Rs',
            options: [
              {
                name: "0 to 5,00,000",
                header: 'Use for Promotions, Branding , Advertisements',
                options: [
                  {
                    name: "Web and all social media",
                    header: 'Select License type',
                    isNextStepLastStep: true,
                    isSelectOptions: true,
                    options: [],
                    selections: [
                      {
                        header: "Youtube advertisement",
                        options: [
                          {
                            name: "License validity Expiry( 7 days)",
                            price: 1000,
                          },
                          {
                            name: "License validity Expiry( 31 days)",
                            price: 2500,
                          },
                          {
                            name: "License validity Expiry( 180 days)",
                            price: 4000,
                          },
                          {
                            name: "License validity Expiry( 365 days)",
                            price: 7000,
                          },
                          {
                            name: "License validity Non expiry( For life time)",
                            price: 25000,
                          },
                        ],
                      },
                      {
                        header: "facebook and instagram",
                        options: [
                          {
                            name: "License validity Expiry( 7 days)",
                            price: 2000,
                          },
                          {
                            name: "License validity Expiry( 31 days)",
                            price: 3000,
                          },
                          {
                            name: "License validity Expiry( 180 days)",
                            price: 5000,
                          },
                          {
                            name: "License validity Expiry( 365 days)",
                            price: 8000,
                          },
                          {
                            name: "License validity Non expiry( For life time)",
                            price: 35000,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Broadcast for TV or Radio Platforms",
                    header: 'Select License type',
                    isNextStepLastStep: true,
                    isSelectOptions: true,
                    options: [],
                    selections: [
                      {
                        header: "Regional",
                        options: [
                          {
                            name: "License validity Expiry( 7 days)",
                            price: 2500,
                          },
                          {
                            name: "License validity Expiry( 31 days)",
                            price: 3500,
                          },
                          {
                            name: "License validity Expiry( 180 days)",
                            price: 6000,
                          },
                          {
                            name: "License validity Expiry( 365 days)",
                            price: 9000,
                          },
                          {
                            name: "License validity Non expiry( For life time)",
                            price: 25000,
                          },
                        ],
                      },
                      {
                        header: "National",
                        options: [
                          {
                            name: "License validity Expiry( 7 days)",
                            price: 3500,
                          },
                          {
                            name: "License validity Expiry( 31 days)",
                            price: 5000,
                          },
                          {
                            name: "License validity Expiry( 180 days)",
                            price: 8000,
                          },
                          {
                            name: "License validity Expiry( 365 days)",
                            price: 15000,
                          },
                          {
                            name: "License validity Non expiry( For life time)",
                            price: 50000,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Event or location use",
                    header: 'Select License type',
                    isNextStepLastStep: true,
                    isSelectOptions: true,
                    options: [],
                    selections: [
                      {
                        header: "Below 500 audience",
                        options: [
                          {
                            name: "License validity Expiry( 7 days)",
                            price: 2500,
                          },
                          {
                            name: "License validity Expiry( 31 days)",
                            price: 6000,
                          },
                          {
                            name: "License validity Expiry( 180 days)",
                            price: 8000,
                          },
                          {
                            name: "License validity Expiry( 365 days)",
                            price: 9000,
                          },
                          {
                            name: "License validity Non expiry( For life time)",
                            price: 25000,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                name: "5,00,000 and above",
                header: 'Use for Promotions, Branding , Advertisements',
                options: [
                  {
                    name: "Web and all social media",
                    header: 'Select License type',
                    isNextStepLastStep: true,
                    isSelectOptions: true,
                    options: [],
                    selections: [
                      {
                        header: "youtube",
                        options: [
                          {
                            name: "License validity Expiry( 7 days)",
                            price: 3500,
                          },
                          {
                            name: "License validity Expiry( 31 days)",
                            price: 6000,
                          },
                          {
                            name: "License validity Expiry( 180 days)",
                            price: 9000,
                          },
                          {
                            name: "License validity Expiry( 365 days)",
                            price: 25000,
                          },
                          {
                            name: "License validity Non expiry( For life time)",
                            price: 55000,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Broadcast for TV or Radio Platforms",
                    header: 'Select License type',
                    isNextStepLastStep: true,
                    isSelectOptions: true,
                    options: [],
                    selections: [
                      {
                        header: "Regional",
                        options: [
                          {
                            name: "License validity Expiry( 7 days)",
                            price: 5000,
                          },
                          {
                            name: "License validity Expiry( 31 days)",
                            price: 7000,
                          },
                          {
                            name: "License validity Expiry( 180 days)",
                            price: 9000,
                          },
                          {
                            name: "License validity Expiry( 365 days)",
                            price: 12000,
                          },
                          {
                            name: "License validity Non expiry( For life time)",
                            price: 25000,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Film,documentary,shows",
            header: 'Business turnover in the previous year is between Rs',
            isNextStepLastStep: true,
            isSelectOptions: false,
            options: [],
            selections: [
              {
                header: "",
                options: [
                  {
                    name: "Not Available",
                  },
                ],
              },
            ],
          },
          {
            name: "Youtube",
            header: 'Youtube with Subscribers',
            options: [
              {
                name: "50,000 to 5,00,000 subscribers",
                header: 'Youtube with 50,000 to 5,00,000 subscribers',
                isNextStepLastStep: true,
                isSelectOptions: true,
                options: [],
                selections: [
                  {
                    header: "Full song as Background in your video",
                    options: [
                      {
                        name: "License validity Expire( 1 year)",
                        price: 1500,
                      },
                      {
                        name: "License validity Non Expiry",
                        price: 2500,
                      },
                    ],
                  },
                  {
                    header: "To perform the song-Sing / dance / play music",
                    options: [
                      {
                        name: "License validity Expire( 1 year)",
                        price: 500,
                      },
                      {
                        name: "License validity Non Expiry",
                        price: 1500,
                      },
                    ],
                  },
                ],
              },
              {
                name: "5,00,000 to 20,00,000 subscribers",
                header: 'Youtube with 5,00,000 to 20,00,000 subscribers',
                isNextStepLastStep: true,
                isSelectOptions: true,
                options: [],
                selections: [
                  {
                    header: "Full song as Background in your video",
                    options: [
                      {
                        name: "License validity Expire( 1 year)",
                        price: 1800,
                      },
                      {
                        name: "License validity Non Expiry",
                        price: 2600,
                      },
                    ],
                  },
                ],
              },
              {
                name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                header: 'Youtube with 20 lakhs(2 million) to 1 crore (10 million) subscribers',
                isNextStepLastStep: true,
                isSelectOptions: true,
                options: [],
                selections: [
                  {
                    header: "Full song as Background in your video",
                    options: [
                      {
                        name: "License validity Expire( 1 year)",
                        price: 2000,
                      },
                      {
                        name: "License validity Non Expiry",
                        price: 3000,
                      },
                    ],
                  },
                ],
              },
              {
                name: "More than 1 crore (10 million) subscribers",
                header: 'Youtube with More than 1 crore (10 million) subscribers',
                isNextStepLastStep: true,
                isSelectOptions: true,
                options: [],
                selections: [
                  {
                    header: "Full song as Background in your video",
                    options: [
                      {
                        name: "License validity Expire( 1 year)",
                        price: 2500,
                      },
                      {
                        name: "License validity Non Expiry",
                        price: 3000,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Instagram",
            header: 'Instagram with Subscribers',
            isNextStepLastStep: true,
            isSelectOptions: false,
            options: [],
            selections: [
              {
                header: "",
                options: [
                  {
                    name: "Not Available",
                  },
                ],
              },
            ],
          },
          {
            name: "Facebook",
            header: 'Facebook with Subscribers',
            isNextStepLastStep: true,
            isSelectOptions: false,
            options: [],
            selections: [
              {
                header: "",
                options: [
                  {
                    name: "Not Available",
                  },
                ],
              },
            ],
          },
          {
            name: "To Create , perform Remix of the song or tune or lyrics",
            header: 'To Create , perform Remix of the song or tune or lyrics',
            options: [
              {
                name: "Youtube",
                header: 'To Create , perform Remix of the song or tune or lyrics - Youtube',
                options: [
                  {
                    name: "50,000 to 5,00,000 subscribers",
                    header: 'To Create , perform Remix of the song or tune or lyrics - Youtube',
                    isNextStepLastStep: true,
                    isSelectOptions: true,
                    options: [],
                    selections: [
                      {
                        header: "Add a heading",
                        options: [
                          {
                            name: "License validity Expire( 1 year)",
                            price: 1500,
                          },
                          {
                            name: "License validity Non Expiry",
                            price: 3500,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: "5,00,000 to 20,00,000 subscribers",
                    header: 'To Create , perform Remix of the song or tune or lyrics - Youtube',
                    isNextStepLastStep: true,
                    isSelectOptions: true,
                    options: [],
                    selections: [
                      {
                        header: "Add a heading",
                        options: [
                          {
                            name: "License validity Expire( 1 year)",
                            price: 2000,
                          },
                          {
                            name: "License validity Non Expiry",
                            price: 6000,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                    header: 'To Create , perform Remix of the song or tune or lyrics - Youtube',
                    isNextStepLastStep: true,
                    isSelectOptions: true,
                    options: [],
                    selections: [
                      {
                        header: "Add a heading",
                        options: [
                          {
                            name: "License validity Expire( 1 year)",
                            price: 3000,
                          },
                          {
                            name: "License validity Non Expiry",
                            price: 8000,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: "More than 1 crore (10 million) subscribers",
                    header: 'To Create , perform Remix of the song or tune or lyrics - Youtube',
                    isNextStepLastStep: true,
                    isSelectOptions: true,
                    options: [],
                    selections: [
                      {
                        header: "Add a heading",
                        options: [
                          {
                            name: "License validity Expire( 1 year)",
                            price: 5000,
                          },
                          {
                            name: "License validity Non Expiry",
                            price: 13000,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                name: "Facebook and Instagram",
                header: 'To Create , perform Remix of the song or tune or lyrics - Instagram and facebook',
                isNextStepLastStep: true,
                isSelectOptions: false,
                options: [],
                selections: [
                  {
                    header: "",
                    options: [
                      {
                        name: "Not Available",
                      },
                    ],
                  },
                ],
              },
              {
                name: "Broadcast for TV or Radio Platforms",
                header: 'Broadcast for TV or Radio Platforms',
                options: [
                  {
                    name: "Regional",
                    header: 'Broadcast for TV or Radio Platforms -Regional',
                    isNextStepLastStep: true,
                    isSelectOptions: true,
                    options: [],
                    selections: [
                      {
                        header: "Add a heading",
                        options: [
                          {
                            name: "License validity Expire( 1 year)",
                            price: 1500,
                          },
                          {
                            name: "License validity Non Expiry",
                            price: 5000,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: "National",
                    header: 'Broadcast for TV or Radio Platforms -National',
                    isNextStepLastStep: true,
                    isSelectOptions: true,
                    options: [],
                    selections: [
                      {
                        header: "Add a heading",
                        options: [
                          {
                            name: "License validity Expire( 1 year)",
                            price: 5000,
                          },
                          {
                            name: "License validity Non Expiry",
                            price: 15000,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: "International",
                    header: 'Broadcast for TV or Radio Platforms -International',
                    isNextStepLastStep: true,
                    isSelectOptions: true,
                    options: [],
                    selections: [
                      {
                        header: "Add a heading",
                        options: [
                          {
                            name: "License validity Expire( 1 year)",
                            price: 20000,
                          },
                          {
                            name: "License validity Non Expiry",
                            price: 45000,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                name: "Perform the remix at Event or public location",
                header: 'Perform the remix at Event or public location',
                isNextStepLastStep: true,
                isSelectOptions: true,
                options: [],
                selections: [
                  {
                    header: "Below 500 audience",
                    options: [
                      {
                        name: "License validity Expiry( 1 Year)",
                        price: 2500,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Negotiate with us (copyright owners) directly for other license needs",
            header: 'Negotiate with us (copyright owners) directly for other license needs',
            isNextStepLastStep: true,
            isSelectOptions: false,
            options: [],
            selections: [
              {
                header: "",
                options: [
                  {
                    name: "Email: permcop.director@gmail.com",
                  },
                  {
                    name: "Mobile: 7358971818",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Metaverse",
        header: 'Metaverse',
        isNextStepLastStep: true,
        isSelectOptions: true,
        options: [],
        selections: [
          {
            header: "",
            options: [
              {
                name: "Metaverse",
                price: 7500,
              },
            ],
          },
        ],
      },
      {
        name: "Specific / Custom licenses",
        header: 'Specific / Custom licenses',
        isNextStepLastStep: true,
        isSelectOptions: false,
        options: [],
        selections: [
          {
            header: "",
            options: [
              {
                name: "Email: permcop.director@gmail.com",
              },
              {
                name: "Mobile: 7358971818",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "A common general license to use any song",
    header: 'A common general license to use any song',
    options: [],
    selections: [
      {
        header: "",
        options: [
          {
            name: "COMING_SOON",
          },
        ],
      },
    ],
  },
];
