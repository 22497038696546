import React from 'react';
import './musicuploard.css'; // Import the CSS for styling

const ProgressBar = ({ step, totalStep }) => {
  return (
    <div className="progress-container">
      {Array.from({ length: totalStep }).map((_, index) => (
        <div
          key={index}
          className={`progress-step ${step >= index + 1 ? 'active' : ''}`}
        ></div>
      ))}
    </div>
  );
};

export default ProgressBar;
