import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap"; // Bootstrap for layout
import './pricingFLow.css'

const SingingDanceForm = ({
  prevStep,
  nextstep,
  pricingData,
  setFormData,
  formTitle
}) => {
  // State to manage price inputs for the static fields
  const [performancePrices, setPerformancePrices] = useState([
    { name: "Singing", price: "" },
    { name: "Dance performance", price: "" },
  ]);

  const handleFormSave = () => {
    setFormData({
      ...pricingData,
      pricingValues: performancePrices,
    });
    nextstep();
  };

  const isAllFieldsFilled = () => {
    return performancePrices.every((form) =>
      form.price.trim() !== '')
  };

  return (
    <form className="Progress_form">
      <h4 style={{marginLeft: '15px'}}>{formTitle}</h4>
      {performancePrices.map((performance, index) => (
        <div
          key={index}
          style={{
            padding: "0 15px",
            margin: "0 15px"
          }}
        >
          <Row key={index} style={{ marginBottom: '15px', alignItems: 'center' }}>
              <Col xs={8}>
                <Form.Group controlId={`static-heading-${index}`}>
                  <Form.Label style={{ fontWeight: 'bold' }}>{performance.name}</Form.Label>
                </Form.Group>
              </Col>
            <Col xs={4}>
              <Form.Group controlId={`price-${index}`}>
                <Form.Control
                  type="text"
                  value={performance.price}
                  onChange={(e) => {
                    const updatedPrices = performancePrices.map((p, i) =>
                      i === index ? { ...p, price: e.target.value } : p
                    );
                    setPerformancePrices(updatedPrices); // Update state on input change
                  }}
                  placeholder="Enter price"
                  style={{
                    borderRadius: '5px',
                    border: '1px solid black',
                    padding: '5px',
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      ))}

      <div className="btn_wapper">
        <button type="button" onClick={prevStep} className="btn-light">
          Back
        </button>
        <button type="button" onClick={handleFormSave} disabled = {!isAllFieldsFilled()}>
          Save & Next
        </button>
      </div>
    </form>
  );
};

export default SingingDanceForm;
