import React, { useState } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { LEVEL_RESET_MAP, OPTIONS_ARRAY, calculateBuyerLicenseArrayDepth, getSelectionsOptions } from "./BuyContent.helper";
import BuyRadioGroups from "../../../../Dashboard/CreatorDashboard/dashboard/MusicUplorad/components/buy-license/BuyRadioGroups";
import {getHeaderForCurrentLevel, getOptionsForLevel } from "../../../../Dashboard/CreatorDashboard/dashboard/MusicUplorad/Pricing.helper";
import PriceSelection from "../../../../Dashboard/CreatorDashboard/dashboard/MusicUplorad/components/buy-license/PriceSelection";

const BuyContent = ({ setIsMenuOpen }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [pricingData, setPricingData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({
    level1: "",
    level2: "",
    level3: "",
    level4: "",
  });
  const [maxStep, setMaxStep] = useState(4);

  const navigate = useNavigate();

  const nextStep = (level) => {
    if (selectedOptions[level]) {
      setCurrentStep((prevStep) => Math.min(prevStep + 1, maxStep + 1));
    }
  };

  const checkoutHandler = () => {
    console.log("Selections", selectedOptions);
    console.log("Price data", pricingData);
    navigate("/checkout");
  };
  const paymentHandler = () => {
    console.log("Selections", selectedOptions);
    console.log("Price data", pricingData);
    navigate("/payment");
  };

  const handleChange = (e, level) => {
    const { value } = e.target;

    const updatedSelectedOption = {
      ...selectedOptions,
      [level]: value,
      ...LEVEL_RESET_MAP[level],
    };

    setSelectedOptions(updatedSelectedOption);

    // Calculate max steps based on level1 selection
    if (level === "level1") {
      const selectedLevel1Option = OPTIONS_ARRAY.find(
        (option) => option.name === value
      );
      const newMaxStep = calculateBuyerLicenseArrayDepth(
        selectedLevel1Option.options
      );
      setMaxStep(newMaxStep + 1); // Adding 1 for the final static step
    } else if (level === "level2") {
      console.log(
        "jjjj",
        getOptionsForLevel("level1", OPTIONS_ARRAY, updatedSelectedOption)
      );
      const selectedLevel2Option = getOptionsForLevel(
        "level1",
        OPTIONS_ARRAY,
        updatedSelectedOption
      ).find((option) => option.name === value);
      const newMaxStep = calculateBuyerLicenseArrayDepth(
        selectedLevel2Option.options
      );
      setMaxStep(1 + newMaxStep + 1);
    } else if (level === "level3") {
      const selectedLevel3Option = getOptionsForLevel(
        "level2",
        getOptionsForLevel("level1", OPTIONS_ARRAY, updatedSelectedOption),
        updatedSelectedOption
      ).find((option) => option.name === value);
      const newMaxStep = calculateBuyerLicenseArrayDepth(
        selectedLevel3Option.options
      );
      setMaxStep(2 + newMaxStep + 1);
    } else if (level === "level4") {
      const selectedLevel4Option = getOptionsForLevel(
        "level3",
        getOptionsForLevel(
          "level2",
          getOptionsForLevel("level1", OPTIONS_ARRAY, updatedSelectedOption),
          updatedSelectedOption
        ),
        updatedSelectedOption
      ).find((option) => option.name === value);
      const newMaxStep = calculateBuyerLicenseArrayDepth(
        selectedLevel4Option.options
      );
      setMaxStep(3 + newMaxStep + 1);
    } 
    // else if (level === "level5") {
    //   const selectedLevel5Option = getOptionsForLevel(
    //     "level4",
    //     getOptionsForLevel(
    //       "level3",
    //       getOptionsForLevel(
    //         "level2",
    //         getOptionsForLevel("level1", OPTIONS_ARRAY, updatedSelectedOption),
    //         updatedSelectedOption
    //       ),
    //       updatedSelectedOption
    //     ),
    //     updatedSelectedOption
    //   ).find((option) => option.name === value);
    //   const newMaxStep = calculateBuyerLicenseArrayDepth(
    //     selectedLevel5Option.options
    //   );
    //   setMaxStep(4 + newMaxStep + 1);
    // } 
    // else if (level === "level6") {
    //   const selectedLevel6Option = getOptionsForLevel(
    //     "level5",
    //     getOptionsForLevel(
    //       "level4",
    //       getOptionsForLevel(
    //         "level3",
    //         getOptionsForLevel(
    //           "level2",
    //           getOptionsForLevel(
    //             "level1",
    //             OPTIONS_ARRAY,
    //             updatedSelectedOption
    //           ),
    //           updatedSelectedOption
    //         ),
    //         updatedSelectedOption
    //       ),
    //       updatedSelectedOption
    //     ),
    //     updatedSelectedOption
    //   ).find((option) => option.name === value);
    //   const newMaxStep = calculateBuyerLicenseArrayDepth(
    //     selectedLevel6Option.options
    //   );
    //   setMaxStep(5 + newMaxStep + 1);
    // }
  };

  const prevStep = (level) => {
    if (level === "level1") {
      setIsMenuOpen(false);
      return;
    }
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [level]: "", // Dynamically update the selected level to an empty string
    }));
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  // console.log('header', getFinalHeader('level2', OPTIONS_ARRAY, selectedOptions))

  const renderForm = () => {
    switch (currentStep) {
      case maxStep + 1:
        return (
          <>
            {}
            <span className="searchbycodestep" onClick={prevStep}>
              <MdOutlineKeyboardArrowLeft className="backbtnhandlercolor" />
            </span>
            <span className="searchbycodestep">
              Step {currentStep}/{maxStep + 1}
            </span>
            <h5 style={{ textAlign: "start" }}>{getSelectionsOptions(selectedOptions, OPTIONS_ARRAY)?.header || ''}</h5>
            <PriceSelection
              data={getSelectionsOptions(selectedOptions, OPTIONS_ARRAY)}
              setSelectedOptions={setPricingData}
            />
            <span className="btn_mobis">
              <div className="btn_Twowrapper">
                <button type="button" onClick={() => checkoutHandler()}>
                  Add to Cart
                </button>
                <button type="button" onClick={() => paymentHandler()}>
                  Buy Now
                </button>
              </div>
            </span>
          </>
        );
      case 1:
        return (
          <>
            <div>
              {}
              <span
                className="searchbycodestep"
                onClick={() => prevStep("level1")}
              >
                <MdOutlineKeyboardArrowLeft className="backbtnhandlercolor" />
              </span>
              <span className="searchbycodestep">
                Step {currentStep}/{maxStep + 1}
              </span>
              <h5 style={{ textAlign: "start" }}>Select Licence Type</h5>
              <BuyRadioGroups
                optionsArray={OPTIONS_ARRAY}
                level="level1"
                selectedOptions={selectedOptions}
                handleChange={handleChange}
              />
            </div>
            <span className="btn_mobis">
              <button
                type="button"
                onClick={() => nextStep("level1")}
                className="btn_three"
              >
                Next
              </button>
            </span>
          </>
        );
      case 2:
        return (
          <>
            <div>
              <span
                className="searchbycodestep"
                onClick={() => prevStep("level2")}
              >
                <MdOutlineKeyboardArrowLeft className="backbtnhandlercolor" />
              </span>
              <span className="searchbycodestep">
                Step {currentStep}/{maxStep + 1}
              </span>
              <h5 style={{ textAlign: "start" }}>{getHeaderForCurrentLevel(2, OPTIONS_ARRAY, selectedOptions)}:</h5>
              <BuyRadioGroups
                level="level2"
                optionsArray={OPTIONS_ARRAY}
                selectedOptions={selectedOptions}
                handleChange={handleChange}
              />
            </div>
            <span className="btn_mobis">
              <button
                type="button"
                onClick={() => nextStep("level2")}
                className="btn_three"
              >
                Next
              </button>
            </span>
          </>
        );
      case 3:
        return (
          <>
            <div>
              <span
                className="searchbycodestep"
                onClick={() => prevStep("level3")}
              >
                <MdOutlineKeyboardArrowLeft className="backbtnhandlercolor" />
              </span>
              <span className="searchbycodestep">
                Step {currentStep}/{maxStep + 1}
              </span>
              <h5 style={{ textAlign: "start" }}>
                {getHeaderForCurrentLevel(3, OPTIONS_ARRAY, selectedOptions)}:
              </h5>
              <BuyRadioGroups
                level="level3"
                optionsArray={OPTIONS_ARRAY}
                selectedOptions={selectedOptions}
                handleChange={handleChange}
              />
            </div>
            <span className="btn_mobis">
              <button
                type="button"
                onClick={() => nextStep("level3")}
                className="btn_three"
              >
                Next
              </button>
            </span>
          </>
        );
      case 4:
        return (
          <>
            <div>
              <span
                className="searchbycodestep"
                onClick={() => prevStep("level4")}
              >
                <MdOutlineKeyboardArrowLeft className="backbtnhandlercolor" />
              </span>
              <span className="searchbycodestep">
                Step {currentStep}/{maxStep + 1}
              </span>
              <h5 style={{ textAlign: "start" }}>
                {getHeaderForCurrentLevel(4, OPTIONS_ARRAY, selectedOptions)}:
              </h5>
              <BuyRadioGroups
                level="level4"
                optionsArray={OPTIONS_ARRAY}
                selectedOptions={selectedOptions}
                handleChange={handleChange}
              />
            </div>
            <span className="btn_mobis">
              <button
                type="button"
                onClick={() => nextStep("level4")}
                className="btn_three"
              >
                Next
              </button>
            </span>
          </>
        );
      // case 5:
      //   return (
      //     <>
      //       <div>
      //         <span
      //           className="searchbycodestep"
      //           onClick={() => prevStep("level5")}
      //         >
      //           <MdOutlineKeyboardArrowLeft className="backbtnhandlercolor" />
      //         </span>
      //         <span className="searchbycodestep">
      //           Step {currentStep}/{maxStep + 1}
      //         </span>
      //         <h4 style={{ textAlign: "start" }}>
      //           {selectedOptions?.level1} - {selectedOptions?.level2} -{" "}
      //           {selectedOptions?.level3} - {selectedOptions?.level4}:
      //         </h4>
      //         <BuyRadioGroups
      //           level="level5"
      //           optionsArray={OPTIONS_ARRAY}
      //           selectedOptions={selectedOptions}
      //           handleChange={handleChange}
      //         />
      //       </div>
      //       <span className="btn_mobis">
      //         <button
      //           type="button"
      //           onClick={() => nextStep("level5")}
      //           className="btn_three"
      //         >
      //           Next
      //         </button>
      //       </span>
      //     </>
      //   );
      // case 6:
      //   return (
      //     <>
      //       <div>
      //         <span
      //           className="searchbycodestep"
      //           onClick={() => prevStep("level6")}
      //         >
      //           <MdOutlineKeyboardArrowLeft className="backbtnhandlercolor" />
      //         </span>
      //         <span className="searchbycodestep">
      //           Step {currentStep}/{maxStep + 1}
      //         </span>
      //         <h4 style={{ textAlign: "start" }}>
      //           {selectedOptions?.level1} - {selectedOptions?.level2} -{" "}
      //           {selectedOptions?.level3} - {selectedOptions?.level4}:
      //         </h4>
      //         <BuyRadioGroups
      //           level="level6"
      //           optionsArray={OPTIONS_ARRAY}
      //           selectedOptions={selectedOptions}
      //           handleChange={handleChange}
      //         />
      //       </div>
      //       <span className="btn_mobis">
      //         <button
      //           type="button"
      //           onClick={() => nextStep("level6")}
      //           className="btn_three"
      //         >
      //           Next
      //         </button>
      //       </span>
      //     </>
      //   );
      default:
        return null;
    }
  };

  return (
    <form className="Progress_form" style={{ marginTop: "0px" }}>
      {renderForm()}
    </form>
  );
};

export default BuyContent;
