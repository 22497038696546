import React, { useState, useEffect } from "react";
import "./HeaderNew.css";
import UserLogo from "../../assets/user-profile.svg";
import Settings from '../../assets/settings.svg';
import { useNavigate } from "react-router-dom";
import { GetCurrentUser } from "../../Hooks/ApiService";
import { FaBars } from 'react-icons/fa'; // Import Hamburger icon

const HeaderNew = ({ toggleSidebar }) => { // Accept toggleSidebar as prop
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const User = await GetCurrentUser();
      if (User) {
        setIsLoggedIn(true);
      }
    };
    fetchUser();
  }, []);

  const loginHandler = () => {
    navigate("/auth");
  };

  return (
    <>
      <div className="header">
        <div className="header_main">
          <div className="left_header">
            {/* Hamburger Menu */}
          <FaBars className="hamburger" onClick={toggleSidebar} /> {/* Toggle the sidebar */}
            <p className="header_name" onClick={() => navigate("/")}>
              Copyva
            </p>
          </div>
          <div className="Header_bt">
            {isLoggedIn ? (
              <div className="header_right">
                <img src={Settings} width={24} height={24} alt="settings" className="settings_icon" />
                <img
                  src={UserLogo}
                  alt="user_icon"
                  height={42}
                  width={42}
                  className="user_icon"
                />
              </div>
            ) : (
              <button type="button" onClick={() => loginHandler()}>
                Login
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderNew;
