export const LEVEL_RESET_MAP = {
  level1: { level2: "", level3: "", level4: "" },
  level2: { level3: "", level4: "" },
  level3: { level4: "" },
};

export const OPTIONS_ARRAY = [
  {
    name: "Permission to remix/combine the selected content /video by editing",
    header:
      "Permission to remix/combine the selected content /video by editing",
    options: [
      {
        name: "Commercial /business purpose",
        header: "Commercial /business purpose",
        options: [
          {
            name: "Youtube",
            header: "Youtube",
            options: [
              {
                name: "0 to 50,000 subscribers",
                header: "License to use Youtube - 0 to 50,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "50,000 to 5,00,000 subscribers",
                header:
                  "License to use Youtube - 50,000 to 5,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "5,00,000 to 20,00,000 subscribers",
                header:
                  "License to use Youtube - 5,00,000 to 20,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                header:
                  "License to use Youtube - 20 lakhs(2 million) to 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "More than 1 crore (10 million) subscribers",
                header:
                  "License to use Youtube - More than 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
            ],
          },
          {
            name: "Instagram",
            header: "Instagram",
            options: [
              {
                name: "0 to 50,000 subscribers",
                header: "License to use Instagram - 0 to 50,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "50,000 to 5,00,000 subscribers",
                header:
                  "License to use Instagram - 50,000 to 5,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "5,00,000 to 20,00,000 subscribers",
                header:
                  "License to use Instagram - 5,00,000 to 20,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                header:
                  "License to use Instagram - 20 lakhs(2 million) to 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "More than 1 crore (10 million) subscribers",
                header:
                  "License to use Instagram - More than 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
            ],
          },
          {
            name: "Facebook",
            header: "Facebook",
            options: [
              {
                name: "0 to 50,000 subscribers",
                header: "License to use Facebook - 0 to 50,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "50,000 to 5,00,000 subscribers",
                header:
                  "License to use Facebook - 50,000 to 5,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "5,00,000 to 20,00,000 subscribers",
                header:
                  "License to use Facebook - 5,00,000 to 20,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                header:
                  "License to use Facebook - 20 lakhs(2 million) to 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "More than 1 crore (10 million) subscribers",
                header:
                  "License to use Facebook - More than 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
            ],
          },
          {
            name: "Negotiate with us (copyright owners) directly for other license needs",
            header: "Specific / custom licenses",
            formType: "EMAIL_PHONE",
            options: [],
          },
          {
            name: "Add an option if needed",
            isEditable: true,
            header: "#{custom}",
            options: [
              {
                name: "0 to 50,000 subscribers",
                header: "License to use Youtube - 0 to 50,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "50,000 to 5,00,000 subscribers",
                header:
                  "License to use Youtube - 50,000 to 5,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "5,00,000 to 20,00,000 subscribers",
                header:
                  "License to use Youtube - 5,00,000 to 20,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                header:
                  "License to use Youtube - 20 lakhs(2 million) to 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "More than 1 crore (10 million) subscribers",
                header:
                  "License to use Youtube - More than 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
            ],
          },
        ],
      },
      {
        name: "Specific / Custom licenses",
        header: "Specific / custom licenses",
        formType: "EMAIL_PHONE",
        options: [],
      },
    ],
  },
  {
    name: "Permission to repost the same content/video without any edits",
    header: "Permission to repost the same content/video without any edits",
    options: [
      {
        name: "Commercial /business purpose",
        header: "Commercial /business purpose",
        options: [
          {
            name: "Youtube",
            header: "Youtube",
            options: [
              {
                name: "0 to 50,000 subscribers",
                header: "License to use Youtube - 0 to 50,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "50,000 to 5,00,000 subscribers",
                header:
                  "License to use Youtube - 50,000 to 5,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "5,00,000 to 20,00,000 subscribers",
                header:
                  "License to use Youtube - 5,00,000 to 20,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                header:
                  "License to use Youtube - 20 lakhs(2 million) to 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "More than 1 crore (10 million) subscribers",
                header:
                  "License to use Youtube - More than 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
            ],
          },
          {
            name: "Instagram",
            header: "Instagram",
            options: [
              {
                name: "0 to 50,000 subscribers",
                header: "License to use Instagram - 0 to 50,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "50,000 to 5,00,000 subscribers",
                header:
                  "License to use Instagram - 50,000 to 5,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "5,00,000 to 20,00,000 subscribers",
                header:
                  "License to use Instagram - 5,00,000 to 20,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                header:
                  "License to use Instagram - 20 lakhs(2 million) to 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "More than 1 crore (10 million) subscribers",
                header:
                  "License to use Instagram - More than 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
            ],
          },
          {
            name: "Facebook",
            header: "Facebook",
            options: [
              {
                name: "0 to 50,000 subscribers",
                header: "License to use Facebook - 0 to 50,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "50,000 to 5,00,000 subscribers",
                header:
                  "License to use Facebook - 50,000 to 5,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "5,00,000 to 20,00,000 subscribers",
                header:
                  "License to use Facebook - 5,00,000 to 20,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                header:
                  "License to use Facebook - 20 lakhs(2 million) to 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "More than 1 crore (10 million) subscribers",
                header:
                  "License to use Facebook - More than 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
            ],
          },
          {
            name: "Negotiate with us (copyright owners) directly for other license needs",
            header: "Specific / custom licenses",
            formType: "EMAIL_PHONE",
            options: [],
          },
          {
            name: "Add an option if needed",
            isEditable: true,
            header: "#{custom}",
            options: [
              {
                name: "0 to 50,000 subscribers",
                header: "License to use Youtube - 0 to 50,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "50,000 to 5,00,000 subscribers",
                header:
                  "License to use Youtube - 50,000 to 5,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "5,00,000 to 20,00,000 subscribers",
                header:
                  "License to use Youtube - 5,00,000 to 20,00,000 subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "20 lakhs(2 million) to 1 crore (10 million) subscribers",
                header:
                  "License to use Youtube - 20 lakhs(2 million) to 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
              {
                name: "More than 1 crore (10 million) subscribers",
                header:
                  "License to use Youtube - More than 1 crore (10 million) subscribers",
                formType: "EXPIRY_PRICING_FORM_CONTENT_SOCIAL",
                options: [],
              },
            ],
          },
        ],
      },
      {
        name: "Specific / Custom licenses",
        formType: "EMAIL_PHONE",
        options: [],
      },
    ],
  },
];
