import React, { useEffect, useState } from "react";
import "../Auth.css";
import OTPInput from "../../common/otp-input/OtpInput";
import { FORGOT_PASSWORD_FLOWS } from "../../../assets/constants";

const VerifyOtp = ({ setFlowName }) => {
  const [otp, setOtp] = useState();
  const [counter, setCounter] = useState(20);

  useEffect(() => {
    let id;

    if (counter > 0) {
      id = setInterval(() => {
        setCounter((previousValue) =>
          previousValue > 1 ? previousValue - 1 : 0
        );
      }, 1000);
    }

    return () => {
      if (id) {
        clearInterval(id); // Check if id is defined before clearing the interval
      }
    };
  }, [counter]);

  const handleVerify = () => {
    if (otp) {
      console.log("otp", otp);
      // TODO: API call to verify otp
      //AFTER API CALL SUCCESS
      setFlowName(FORGOT_PASSWORD_FLOWS.RESET_PASSWORD);
    }
  };

  return (
    <>
      <h2 className="verify-title">Verify</h2>
      <p className="check_email">Please check your email</p>
      <p className="forget_sub_title">
        We've sent a code to <span className="email">hello@gmail.com</span>
      </p>
      <div className="otp-container">
        <OTPInput otp={otp} setOtp={setOtp} />
      </div>
      {counter > 0 && (
        <p className="resend-code">
          Send code again <span className="timer">{`00:${counter}`}</span>
        </p>
      )}
      {counter === 0 && <p className="resend-code">Send code again</p>}
      <button className="verify-button" onClick={handleVerify}>
        Verify
      </button>
    </>
  );
};
export default VerifyOtp;
